import { CHAIN_INFO } from '@/constants/chains.constant';

class MetaMaskConnector {
  constructor() {}

  getProvider() {
    // return window?.ethereum;
    let provider = null;
    if (typeof window.ethereum !== 'undefined') {
      window?.ethereum?.providers?.forEach(async (p) => {
        if (p.isMetaMask) provider = p;
      });
      if (!provider) {
        provider = window.ethereum;
      }
    }
    return provider;
  }

  subscribeToEvents(chainChangeCb, accountChangeCb, disconnectCb) {
    const provider = this.getProvider();
    if (!provider) return;
    if (provider?.removeAllListeners) {
      provider.removeAllListeners();
    }

    provider.on('accountsChanged', (accounts) => {
      if (accounts.length) {
        if (accountChangeCb) {
          accountChangeCb(accounts[0]);
        }
      } else {
        if (disconnectCb) {
          console.log('no account availabe, disconnecting...');
          disconnectCb();
        }
      }
    });

    provider.on('chainChanged', (networkId) => {
      const chainId = Number(networkId);
      if (!chainId || isNaN(chainId)) {
        if (disconnectCb) {
          disconnectCb();
        }
      } else {
        if (chainChangeCb) {
          chainChangeCb(chainId);
        }
      }
    });
  }

  addChain({ explorer, name, nativeCurrency, rpcUrl, chain }) {
    const provider = this.getProvider();
    provider.request({
      method: 'wallet_addEthereumChain',
      params: [
        {
          chainId: this.toHex(chain),
          chainName: name,
          nativeCurrency: {
            ...nativeCurrency,
          },
          rpcUrls: [rpcUrl],
          blockExplorerUrls: [explorer],
        },
      ],
    });
  }

  async _switchChain({ explorer, name, nativeCurrency, rpcUrl, chain }) {
    try {
      const provider = this.getProvider();
      await provider.request({
        method: 'wallet_switchEthereumChain',
        params: [{ chainId: this.toHex(chain) }],
      });
    } catch (error) {
      console.error('in switch chain', error);
      if (error.code === 4902) {
        this.addChain({
          explorer,
          name,
          nativeCurrency,
          rpcUrl,
          chain,
        });
      } else {
        throw error;
      }
    }
  }

  async switchChain(chain) {
    try {
      const provider = this.getProvider();
      await provider.request({
        method: 'wallet_switchEthereumChain',
        params: [{ chainId: this.toHex(chain) }],
      });
    } catch (error) {
      console.error('in switch chain', error);
      if (error.code === 4902) {
        this.addChain({
          ...CHAIN_INFO[chain],
          chain,
        });
      } else {
        throw error;
      }
    }
  }

  toHex(chainIdDec) {
    const chainInHex = `0x${Number(chainIdDec).toString(16)}`;
    return chainInHex;
  }

  async connect(chain) {
    const chainInfo = CHAIN_INFO[chain];
    const provider = this.getProvider();
    if (!provider) {
      throw new Error('Please install Metamask!');
    }
    const chainId = Number(await provider.request({ method: 'eth_chainId' }));
    if (chainId !== chain) {
      await this._switchChain({ ...chainInfo, chain });
    }
    await provider.request({ method: 'eth_requestAccounts' });
  }

  async disconnect() {
    const provider = this.getProvider();
    if (provider) {
      if (provider.removeAllListeners) {
        provider.removeAllListeners();
      }
      if (provider.close) {
        provider.close();
      }
      if (provider.disconnect) {
        provider.disconnect();
      }
    }
    window.localStorage.removeItem('MATSURI_CONNECTOR');
  }
}

export default new MetaMaskConnector();
