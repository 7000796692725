<template>
  <section>
    <TermConditionModal
      v-model:visible="visible"
      @accept="handleAccept"
      @reject="handleReject"
    />
    <div class="default-footer">
      <div class="default-footer__top flex flex-row sm:flex-col justify-around mb-8 sm:mb-0">
        <div class="flex flex-1 justify-center items-center">
          <img
            src="/images/footer_logo.png"
            alt="Masuri"
            style="max-width: 100px"
          />
        </div>
        <div>
          <ul
            class="flex flex-col sm:flex-row items-center justify-center text-base my-8 leading-8"
          >
            <li>
              <a
                role="button"
                class="mx-4"
                href="/files/riyoukiyaku230703.pdf"
                target="_blank"
                >{{ $t('TERMS_OF_SERVICES') }}</a
              >
            </li>
            <li>
              <a
                href="/files/privacypolicy.pdf"
                target="_blank"
                class="mx-4"
                >{{ $t('PRIVACY_POLICY') }}</a
              >
            </li>
            <li>
              <a
                href="/files/tokusyouhou230703.pdf"
                target="_blank"
                class="mx-4"
                >{{ $t('COMERCIAL_NOTATION') }}</a
              >
            </li>
            <li>
              <a
                href="https://forms.gle/Lp5i8BN1CVC9kjh19"
                target="_blank"
                class="mx-4"
                >{{ $t('CONTACT_US') }}</a
              >
            </li>
          </ul>
        </div>
      </div>
      <div class="default-footer__copyright">{{ $t('COPY_RIGHT') }}</div>
    </div>
  </section>
</template>

<script>
import { ref } from 'vue';
import TermConditionModal from './common/TermConditionModal.vue';

export default {
  name: 'DefaultFooter',
  setup() {
    const visible = ref(false);
    const showModal = () => {
      visible.value = true;
    };
    const handleAccept = (e) => {
      localStorage.setItem('accepted', 'true');
      visible.value = false;
    };
    const handleReject = (e) => {
      visible.value = false;
    };
    return {
      visible,
      showModal,
      handleAccept,
      handleReject,
    };
  },
  components: { TermConditionModal },
};
</script>

<style lang="scss">
.default-footer {
  &__top {
    background: white;
    @apply pt-14;
  }

  ul {
    li {
      @apply mr-4;
      &:last-child {
        @apply mr-0;
      }
      a {
        color: black;
        &:hover {
          text-decoration: underline;
        }
      }
    }
  }
  &__copyright {
    color: black;
    @apply pb-2;
    @apply text-center text-sm;
  }
}
</style>
