<template>
  <a-button class="w-full">
    <slot></slot>
  </a-button>
</template>

<script>
import notifyUtil from '@/utils/notify.util';
import { CONNECTORS } from '@/constants/types.constant';
import { inject, onMounted } from 'vue';
import { useI18n } from 'vue-i18n';
import useConnectorWatcher from '@/composables/use-connector-watcher';
import { useStore } from 'vuex';
export default {
  components: {},
  setup() {
    const store = useStore();
    const i18n = useI18n();
    const connector = inject('metamaskConnector');
    const { listeningToEvents, updateUserInfo } = useConnectorWatcher(CONNECTORS.METAMASK);
    async function connectWallet(chainId) {
      try {
        await connector.connect(chainId);
        const web3Provider = connector.getProvider();
        await updateUserInfo(web3Provider);
        listeningToEvents();
      } catch (err) {
        notifyUtil.error(i18n.t, err);
      }
    }

    onMounted(async () => {
      try {
        store.commit('setting/CHANGE_GLOBAL_LOADING', true);

        const provider = connector.getProvider();
        if (provider && window.localStorage.getItem('MATSURI_CONNECTOR') === CONNECTORS.METAMASK) {
          await updateUserInfo(provider);
        }
      } catch (err) {
        console.error(err);
      } finally {
        store.commit('setting/CHANGE_GLOBAL_LOADING', false);
      }
    });

    return {
      connectWallet,
    };
  },
};
</script>

<style></style>
