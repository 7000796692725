<template>
  <a
    v-if="isShow"
    href="#"
    class="back-to-top-wrapper"
  >
    <span class="wp-arrow"></span>
  </a>
</template>
<script>
const BACK_TO_TOP_SHOW_ON_PX = 100;

export default {
  components: {},
  data() {
    return {
      isShow: false,
    };
  },
  mounted() {
    window.addEventListener('scroll', this.handleScroll);
    this.handleScroll();
  },
  beforeUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  },
  methods: {
    handleScroll(event) {
      if (
        document.body.scrollTop > BACK_TO_TOP_SHOW_ON_PX ||
        document.documentElement.scrollTop > BACK_TO_TOP_SHOW_ON_PX
      ) {
        if (!this.isShow) {
          this.isShow = true;
        }
      } else {
        if (this.isShow) {
          this.isShow = false;
        }
      }
    },
  },
};
</script>

<style lang="scss">
.back-to-top-wrapper {
  position: fixed;
  bottom: 30px;
  right: 24.5px;
  z-index: 99;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 60px;
  height: 60px;
  filter: drop-shadow(0px 8px 16px rgba(40, 40, 43, 0.1));
  border-radius: 50%;
  background: #d72500;
  &:hover {
    opacity: 0.7;
  }
  .wp-arrow {
    width: 0;
    height: 0;
    border-right: 16px solid transparent;
    border-left: 16px solid transparent;
    border-bottom: 20px solid #fff;
    margin-bottom: 4px;
  }
  @media (max-width: 767px) {
    bottom: 80px;
    width: 40px;
    height: 40px;
    .wp-arrow {
      border-right: 10px solid transparent;
      border-left: 10px solid transparent;
      border-bottom: 14px solid #fff;
      margin-bottom: 4px;
    }
  }
}
</style>
