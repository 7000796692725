import { createRouter, createWebHistory } from 'vue-router';

const routes = [
  {
    path: '/',
    component: () => import('@/pages/index.page'),
    children: [
      {
        path: '',
        name: 'Home page',
        component: () => import('@/pages/home.page'),
      },
      {
        path: 'search',
        name: 'Search',
        component: () => import('@/pages/search.page'),
      },
      {
        path: 'shop',
        name: 'Shop',
        component: () => import('@/pages/shop.page'),
      },
      {
        path: 'shop/:id',
        name: 'Shop Detail',
        component: () => import('@/pages/shop-detail.page'),
      },
      {
        path: 'category',
        name: 'Category',
        component: () => import('@/pages/category.page'),
      },
      {
        path: 'mint/:id',
        name: 'NFT Mint Page',
        component: () => import('@/pages/detail.page'),
      },
      {
        path: 'nft/:id',
        name: 'NFT Details Page',
        component: () => import('@/pages/nft-detail.page'),
      },
      {
        path: '/my-page',
        name: 'my-page',
        component: () => import('@/pages/my-page.vue'),
      },
      {
        path: '/my-page/edit',
        name: 'my-page-edit',
        component: () => import('@/pages/my-page-edit.vue'),
      },
      {
        path: '/my-shop',
        name: 'my-shop',
        component: () => import('@/pages/my-shop.vue'),
      },
      {
        path: '/about',
        name: 'about',
        component: () => import('@/pages/about.page'),
      },
      {
        path: '/upbond-redirect',
        name: 'Upbond Redirect',
        component: () => import('@/pages/admin/upbond-redirect.page'),
      },
    ],
  },
  {
    path: '/manage',
    component: () => import('@/pages/manage.page'),
    children: [
      {
        path: 'projects',
        name: 'Project Management Page',
        component: () => import('@/pages/project-management.page'),
      },
      {
        path: 'other-projects',
        name: 'Other project page',
        component: () => import('@/pages/project-management-other.page'),
      },
      {
        path: 'distributors',
        name: 'Distributor page',
        component: () => import('@/pages/project-management.page'),
      },
      {
        path: 'projects/:id',
        component: () => import('@/pages/project-detail.page'),
        name: 'Project detail page',
      },
      {
        path: 'projects/:id/edit',
        component: () => import('@/pages/create-project.page'),
        name: 'Edit project page',
      },
      {
        path: 'user-projects/create',
        component: () => import('@/pages/create-project.page'),
        name: 'Create project page',
      },
    ],
  },
  {
    path: '/admin',
    name: 'admin',
    component: () => import('@/pages/admin/index.vue'),
    children: [
      {
        path: 'shop',
        name: 'list-shop',
        component: () => import('@/pages/admin/shop-list.vue'),
      },
      {
        path: 'shop/create',
        name: 'create-shop',
        component: () => import('@/pages/admin/shop-form.vue'),
      },
      {
        path: 'shop/:id/edit',
        name: 'edit-shop',
        component: () => import('@/pages/admin/shop-form-edit.vue'),
      },
      {
        path: 'ads',
        name: 'list-ads',
        component: () => import('@/pages/admin/ads-list.vue'),
      },
      {
        path: 'ads/create',
        name: 'create-ads',
        component: () => import('@/pages/admin/ads-form.vue'),
      },
      {
        path: 'ads/:id/edit',
        name: 'edit-ads',
        component: () => import('@/pages/admin/ads-form.vue'),
      },
      {
        path: 'category',
        name: 'list-category',
        component: () => import('@/pages/admin/category-list.vue'),
      },
      {
        path: 'category/create',
        name: 'create-category',
        component: () => import('@/pages/admin/category-form.vue'),
      },
      {
        path: 'category/:id/edit',
        name: 'edit-category',
        component: () => import('@/pages/admin/category-form.vue'),
      },
      {
        path: 'end-user',
        name: 'list-end-user',
        component: () => import('@/pages/admin/end-user-list.vue'),
      },
      {
        path: 'end-user/:id/detail',
        name: 'detail-end-user',
        component: () => import('@/pages/admin/end-user-detail.vue'),
      },
      {
        path: 'featured-projects',
        name: 'featured-projects',
        component: () => import('@/pages/admin/featured-projects-list.vue'),
      },
    ],
  },
  { path: '/:catchAll(.*)', component: () => import('@/pages/404.page') },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    }
    if (to?.params?.savedPosition === 'true') return {};
    return { top: 0 };
  },
});

export default router;
