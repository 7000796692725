import axios from 'axios';
import { BASE_URL } from '../env';
import store from '@/store';
// import { debounce } from "lodash-es";

export const http = axios.create({
  baseURL: BASE_URL,
  timeout: 7200000, // 2 hours
});

axios.defaults.baseURL = BASE_URL;
// let isAlreadyFetchingAccessToken = false;
// let subscribers = [];

// const onAccessTokenFetched = (access_token) => {
//   const mappedSubcribers = [...subscribers];
//   mappedSubcribers.forEach((callback) => callback(access_token));
//   subscribers = [];
// };

// const addSubscriber = (callback) => {
//   subscribers.push(callback);
// };

// const resetStateAndDisconnect = debounce(() => {
//   store.dispatch("auth/resetAuthStateAndResign");
// }, 2000);
// Add a request interceptor
http.interceptors.request.use(
  (config) => {
    const accessToken = store.getters['wallet/accessToken'];
    const accessTokenType = store.getters['wallet/accessTokenType'];
    if (accessToken) {
      config.headers.Authorization = `${accessTokenType} ${accessToken}`;
    }
    return config;
  },
  (error) => Promise.reject(error)
);

// Add a response interceptor
http.interceptors.response.use(
  (response) => {
    // Return JSON data
    if (response.data) {
      return response.data;
    }
    return response;
  },
  (error) => {
    const err = (error.response && error.response.data) || error;

    // const { config } = error;
    // const originalRequest = config;
    // if (error.response && error.response.status === 401) {
    //   const refreshToken = store.getters["karma/getRefreshToken"];
    //   const isAuthenticated = store.getters["karma/isAuthenticated"];
    //   console.debug({
    //     refreshToken,
    //     isAuthenticated,
    //   });
    //   if (refreshToken) {
    //     if (!isAlreadyFetchingAccessToken) {
    //       isAlreadyFetchingAccessToken = true;
    //       store
    //         .dispatch("auth/refreshAccessToken")
    //         .then(({ access_token }) => {
    //           isAlreadyFetchingAccessToken = false;
    //           onAccessTokenFetched(access_token);
    //         })
    //         .catch(() => {
    //           resetStateAndDisconnect();
    //           return Promise.reject(err);
    //         });
    //     }

    //     const retryOriginalRequest = new Promise((resolve) => {
    //       addSubscriber((access_token) => {
    //         originalRequest.headers.Authorization = `Bearer ${access_token}`;
    //         resolve(axios(originalRequest));
    //       });
    //     });
    //     return retryOriginalRequest;
    //   }
    // }

    if (error.response && error.response.status) {
      error.status = error.response.status;
    }

    return Promise.reject(err);
  }
);

export default http;
