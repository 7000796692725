<template>
  <div class="login-button">
    <TermConditionModal
      :visible="termConditionvisible"
      @accept="handleAcceptTermCondition"
      @reject="handleRejectTermCondition"
    />
    <PopoverUser
      @disconnect="disconnect"
      v-if="is_show_popover_user && account"
    />
    <NavUser
      @disconnect="disconnect"
      v-if="is_show_nav_user && account"
    />
    <div
      class="connect-section"
      v-if="buttonType === 'connect'"
    >
      <a-button
        htmlType="button"
        @click="(e) => connectWallet()"
        class=""
        v-if="!account"
        :ghost="asIcon ? true : false"
      >
        <span v-if="!asIcon">{{ $t('CONNECT_WALLET') }}</span>
        <wallet-icon v-else></wallet-icon>
      </a-button>
      <!-- <a-button
        v-else
        @click="disconnect"
      >
        {{ $t('DISCONNECT') }}
      </a-button> -->
      <!-- <div>{{ account }}/{{ connectedChainId }}</div> -->
    </div>
    <div
      class="switch-section"
      v-else
    >
      <a-button
        htmlType="button"
        @click="switchChain"
        :ghost="asIcon ? true : false"
      >
        <span v-if="!asIcon">
          <span v-if="account">{{
            $t('BUTTON_CONNECT_CHAIN', { chainName: CHAIN_INFO[targetChain].name })
          }}</span>
          <span v-else>{{ $t('CONNECT_WALLET') }}</span>
        </span>
        <wallet-icon v-else></wallet-icon>
      </a-button>
    </div>
    <ConnectModal
      @onCancel="onConnectCancel"
      :visible="isModalConnectVisible"
      :targetChain="targetChain"
    ></ConnectModal>
  </div>
</template>

<script>
import PopoverUser from '@/components/common/PopoverUser.vue';
import NavUser from '@/components/common/NavUser.vue';
import { inject, computed, ref, onMounted } from 'vue';
import { useStore } from 'vuex';
import notifyUtil from '@/utils/notify.util';
import { useI18n } from 'vue-i18n';
import { CHAIN_ID } from '@/env';
import { CHAIN_INFO, CHAIN_ID as CHAIN_IDS } from '@/constants/chains.constant';
import WalletIcon from '@/components/icons/wallet.icon.vue';
import ConnectModal from './ConnectModal.vue';
import { CONNECTORS } from '@/constants/types.constant';
import TermConditionModal from './TermConditionModal.vue';
export default {
  components: {
    PopoverUser,
    NavUser,
    WalletIcon,
    ConnectModal,
    TermConditionModal,
  },
  props: {
    // * connect or switch-chain
    buttonType: {
      type: String,
      default: () => 'connect',
    },
    targetChain: {
      type: Number,
      default: () => CHAIN_ID,
    },
    is_show_popover_user: { type: Boolean, default: false },
    is_show_nav_user: { type: Boolean, default: false },
    asIcon: { type: Boolean, default: false },
  },
  data() {
    return {
      CHAIN_INFO,
    };
  },
  setup(props) {
    const store = useStore();
    const i18n = useI18n();

    /**
     * @type {import('@/plugins/connector-manager').default}
     */
    const connectorManager = inject('authConnector');

    const account = computed(() => {
      return store.getters['wallet/account'];
    });
    const connectedChainId = computed(() => {
      return store.getters['wallet/connectedChainId'];
    });

    async function switchChain() {
      try {
        const connector = connectorManager.getConnector();
        if (
          !account.value ||
          (window.localStorage.getItem('MATSURI_CONNECTOR') === CONNECTORS.UPBOND &&
            !connector.upbond.isLoggedIn)
        ) {
          connectWallet();
          return;
        }
        if (
          window.localStorage.getItem('MATSURI_CONNECTOR') === CONNECTORS.UPBOND &&
          Number(props.targetChain) === Number(CHAIN_IDS.SEPOLIA_TESTNET)
        ) {
          throw 'Upbond does not support this chain';
        }
        if (
          connector?.provider?.session?.peer?.metadata?.name?.toLowerCase().includes('metamask') &&
          connector?.provider?.isWalletConnect
        ) {
          throw new Error('Please switch chain manually');
        }
        await connector.switchChain(props.targetChain);
        return;
      } catch (err) {
        notifyUtil.error(i18n.t, err);
      }
    }

    async function disconnect() {
      store.commit('setting/CHANGE_GLOBAL_LOADING', true);
      try {
        const connector = connectorManager.getConnector();
        await connector?.disconnect();
      } catch (err) {
        // do nothing
      }
      store.commit('wallet/RESET_WALLET_STATE');
      window.localStorage.removeItem('MATSURI_CONNECTOR');
      store.commit('setting/CHANGE_GLOBAL_LOADING', false);
    }

    const isModalConnectVisible = ref(false);
    const onConnectCancel = () => {
      isModalConnectVisible.value = false;
    };
    function connectWallet() {
      termConditionvisible.value = true;
    }

    const termConditionvisible = ref(false);

    const showModal = () => {
      termConditionvisible.value = true;
    };
    const handleAcceptTermCondition = (e) => {
      localStorage.setItem('accepted', 'true');
      isModalConnectVisible.value = true;
      termConditionvisible.value = false;
    };
    const handleRejectTermCondition = (e) => {
      termConditionvisible.value = false;
    };

    return {
      connectWallet,
      switchChain,
      disconnect,
      account,
      connectedChainId,
      isModalConnectVisible,
      onConnectCancel,
      termConditionvisible,
      showModal,
      handleAcceptTermCondition,
      handleRejectTermCondition,
    };
  },
};
</script>

<style lang="scss">
.ant-btn {
  // @apply bg-admin-primary;
}
</style>
