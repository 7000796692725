import http from './axios';
import { API_VERSION_1_PREFIX } from '@/env';

export const getCategories = async ({ limit = 5, offset = 0 } = {}) => {
  const res = await http.get(`${API_VERSION_1_PREFIX}/categories`, {
    params: {
      limit,
      offset,
    },
  });
  return res;
};

export const getCategoriesStats = async ({
  chain_id,
  category_id,
} = {}) => {
  const res = await http.get(`${API_VERSION_1_PREFIX}/marketplace/categories/stats`, {
    params: {
      chain_id,
      category_id
    },
  });
  return res;
};

