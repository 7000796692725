export const LIST_NAV = [
  { url: '/my-shop', text_key: 'NAV_MY_SHOP', isShop: true },
  { url: '/manage/projects', text_key: 'NAV_PROJECT', isAll: true },
  { url: '/manage/other-projects', text_key: 'NAV_OTHER_PROJECT', isShop: true },
  { url: '/admin/ads', text_key: 'NAV_ADS', isAdmin: true },
  { url: '/admin/featured-projects', text_key: 'NAV_FEATURED_PROJECTS', isAdmin: true },
  { url: '/admin/shop', text_key: 'NAV_SHOP', isAdmin: true },
  { url: '/admin/end-user', text_key: 'NAV_END_USER', isAdmin: true },
  { url: '/admin/category', text_key: 'NAV_CATEGORY', isAdmin: true },
  { url: '/my-page', text_key: 'NAV_MY_PAGE', isAll: true, class: 'for-mobile' },
  { url: '/manage/distributors', text_key: 'NAV_DISTRIBUTORS', isAll: true },
];
