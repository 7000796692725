import moment from 'moment';
import { LINK_REGEX } from '@/constants/common.constant';

export function isValidUrl(url) {
  if(!url) return false;
  var regex = new RegExp(LINK_REGEX);
  return regex.test(url);
}

export const getLink = (link) => {
  if (!link) return link;
  if (link.includes('https://') || link.includes('http://')) {
    return link;
  } else {
    return `https://${link}`;
  }
};

export const filterDescriptionLinkWithATag = (description) => {
  const linkArrays = description.match(LINK_REGEX);
  let result = description;
  if (linkArrays) {
    const linkWithATags = linkArrays.map((el) => {
      return `<a target='_blank' href='${el}'>${getLink(el)}</a>`;
    });

    linkArrays.forEach((el, index) => {
      if (result.includes(el)) {
        result = result.replace(el, linkWithATags[index]);
      }
    });
  }
  return result;
};

export const generateCalendarLink = (
  projectName,
  saleType,
  startTime,
  endTime,
  description,
  refLink
) => {
  const baseCalendarUrl = 'https://www.google.com/calendar/render';
  const action = 'TEMPLATE';
  const text = encodeURIComponent(projectName + ' ' + saleType);
  const dates = `${moment.utc(startTime).format('YYYYMMDD[T]HHmmss[Z]')}/${moment
    .utc(endTime)
    .format('YYYYMMDD[T]HHmmss[Z]')}`;
  const details = encodeURIComponent(`Sale link: ${refLink}\n` + description);
  return `${baseCalendarUrl}?action=${action}&text=${text}&dates=${dates}&details=${details}`;
};
