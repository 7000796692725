<template>
  <div class="wp-logined-mobile">
    <img
      class="avatar-user"
      src="/images/user.png"
      alt="user"
    />
    <div class="user-name">
      {{ account }}
    </div>
    <NavigationHeader :is_desktop="false" />
    <button
      class="user-disconnect"
      @click="$emit('disconnect')"
    >
      <IconLogout /> {{ $t('DISCONNECT') }}
    </button>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import NavigationHeader from '@/components/common/NavigationHeader.vue';
import IconLogout from '@/components/icons/logout-icon.vue';
// import { onMounted, beforeUnmount } from 'vue';
export default {
  components: {
    NavigationHeader,
    IconLogout,
  },
  data() {
    return {};
  },
  computed: {
    ...mapState({
      userInfo: (state) => state.wallet?.userInfo || {},
    }),
    ...mapGetters({
      isAdmin: 'wallet/isAdmin',
      account: 'wallet/account',
    }),
  },
  mounted() {},
  beforeUnmount() {},
  methods: {},
};
</script>
<style lang="scss">
.wp-logined-mobile {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  .avatar-user {
    width: 30px;
    aspect-ratio: 1;
    border-radius: 50%;
  }
  .user-name {
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
    color: #38383d;
    word-break: break-all;
    text-align: center;
    margin-top: 8px;
    margin-bottom: 16px;
    padding: 0 24px;
  }
  .user-disconnect {
    padding: 12px 12px;
    display: flex;
    align-items: center;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    color: #ce624a;
    svg {
      margin-right: 12px;
    }
  }
}
</style>
