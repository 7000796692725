<template>
  <div
    :class="[
      'wp-navigation',
      { 'hidden md:block for-desktop': is_desktop },
      { 'for-mobile': !is_desktop },
    ]"
    v-if="isShowNav"
  >
    <router-link
      v-for="(i, idx) in nav_show"
      :key="idx"
      :to="i.url"
      :class="i?.class"
    >
      {{ $t(i.text_key) }}
    </router-link>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { LIST_NAV } from '@/constants/nav.constant.js';
export default {
  props: {
    is_desktop: { type: Boolean, default: true },
  },
  computed: {
    ...mapGetters({
      isAdmin: 'wallet/isAdmin',
      isShop: 'wallet/isShop',
      account: 'wallet/account',
    }),
    nav_show() {
      if (this.isAdmin) return LIST_NAV.filter((i) => i.isAdmin || i.isAll);
      if (this.isShop) return LIST_NAV.filter((i) => i.isShop || i.isAll);
      return LIST_NAV.filter((i) => i.isAll);
    },
    isShowNav() {
      if (!this.is_desktop) return true;
      let matched = false;
      if (this.$route.matched.length) {
        matched = ['/admin', '/manage'].includes(this.$route.matched[0].path);
      }
      if (this.$route.path == '/my-shop') {
        matched = true;
      }
      return this.account && matched;
    },
  },
};
</script>

<style lang="scss">
.wp-navigation {
  @media screen and (min-width: 768px) {
    .for-mobile {
      display: none;
    }
  }
  &.for-desktop {
    @apply bg-white w-full py-4;
    border-top: 1px solid #f5f5f5;
    border-bottom: 1px solid #f5f5f5;
    text-align: center;
    a {
      @apply px-4 text-black text-base;
      &.router-link-active {
        font-weight: 600;
        color: #d72500;
      }
      & + a {
        border-left: 1px solid #707070;
      }
    }
  }
  &.for-mobile {
    @apply bg-white w-full;
    list-style-type: none;
    margin: 0px;
    padding: 0px;
    a {
      padding: 12px 24px;
      display: flex;
      align-items: center;
      font-family: 'Inter';
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 18px;
      color: #38383d;
      border-left: 2px solid transparent;
      cursor: pointer;
      border-bottom: 1px solid #ebebf0;
      .user-nav-icon {
        display: block;
        width: 18px;
        height: 18px;
        &.active {
          display: none;
        }
      }
      &.router-link-active {
        // background: linear-gradient(
        //   89.88deg,
        //   rgba(208, 110, 63, 0.15) -1.73%,
        //   rgba(208, 110, 63, 0) 99.89%
        // );
        color: #d72500;
        .user-nav-icon {
          display: none;
          &.active {
            display: block;
          }
        }
      }
      .user-nav-icon {
        margin-right: 12px;
      }
    }
  }
}
</style>
