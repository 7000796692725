import { getProjectsList, getProjectDetail } from '@/services';
import { getLink } from '@/utils/link.util';
import { getFileName } from '@/utils/common.util';
import {
  UPDATE_PROJECTS_LIST,
  UPDATE_SELECTED_PROJECT,
  UPDATE_TOTAL_PROJECTS,
  UPDATE_PROJECT_DETAIL_MAP,
} from './constants.projects.store';
import { TOKEN_ADDRESSES } from '@/constants/chains.constant';

export default {
  async fetchProjects({ commit }, { limit, offset, search, type }) {
    const data = await getProjectsList({
      limit,
      offset,
      name: search,
      type,
    });
    commit(UPDATE_PROJECTS_LIST, data?.items);
    commit(UPDATE_TOTAL_PROJECTS, data?.total);
  },
  async fetchProjectDetail(context, payload) {
    try {
      let id = '';
      if (payload?.id) {
        id = payload.id;
      } else {
        id = payload;
      }
      const result = await getProjectDetail(id);
      console.log('------>fetchProjectDetail', result);
      console.debug({ result });
      const data = {
        ...result,
        website_url: getLink(result.website_url),
        twitter_url: getLink(result.twitter_url),
        image_uploaded_name: getFileName(result.nft_images_url),
        json_uploaded_name: getFileName(result.nft_json_url),
        whitelist_uploaded_name: getFileName(result.presale_whitelist_url),
        reveal_uploaded_name: getFileName(result.reveal_thumbnail_url),
        banner_uploaded_name: getFileName(result.banner_url),
        thumbnail_uploaded_name: getFileName(result.thumbnail_url),
        formattedDescription: result.description,
        affiliate_json_url: result.referral_url,
        affiliate_json_uploaded_name: getFileName(result.referral_url),
        token_address: result.payment_type ? null : TOKEN_ADDRESSES[result.chain_id]?.USDC,
      };
      context.commit(UPDATE_SELECTED_PROJECT, data);
      context.commit(UPDATE_PROJECT_DETAIL_MAP, [data]);
    } catch (error) {
      context.commit(UPDATE_SELECTED_PROJECT, null);
      context.commit(UPDATE_PROJECT_DETAIL_MAP, []);
    }
    // * No need to call from blockchain
    // const chainId = context.rootState?.wallet?.provider?.chainId || -1;
    // let provider = payload?.provider;
    // if (provider && chainId === result?.chain_id) {
    //   const presale_sold_amount = await getSoldAmount({
    //     provider,
    //     chainId,
    //     isPresale: true,
    //     projectId: result?.contract_project_id,
    //   });
    //   const publicsale_sold_amount = await getSoldAmount({
    //     provider,
    //     chainId,
    //     isPresale: false,
    //     projectId: result?.contract_project_id,
    //   });
    //   if (presale_sold_amount) {
    //     data.presale_sold_amount = presale_sold_amount;
    //   }
    //   if (publicsale_sold_amount) {
    //     data.publicsale_sold_amount = publicsale_sold_amount;
    //   }
    // }
    // context.commit(UPDATE_SELECTED_PROJECT, data);
    // context.commit(UPDATE_PROJECT_DETAIL_MAP, [data]);
  },
};
