<template>
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M14 15L19 10M19 10L14 5M19 10H7M10 15C10 15.2956 10 15.4434 9.98901 15.5714C9.87482 16.902 8.89486 17.9968 7.58503 18.2573C7.45903 18.2823 7.31202 18.2987 7.01835 18.3313L5.99694 18.4448C4.46248 18.6153 3.69521 18.7005 3.08566 18.5055C2.27293 18.2454 1.60942 17.6515 1.26118 16.8725C1 16.2882 1 15.5162 1 13.9723V6.0277C1 4.48377 1 3.7118 1.26118 3.12752C1.60942 2.34848 2.27293 1.7546 3.08566 1.49453C3.69521 1.29947 4.46246 1.38472 5.99694 1.55522L7.01835 1.66871C7.31212 1.70135 7.45901 1.71767 7.58503 1.74273C8.89486 2.00316 9.87482 3.09804 9.98901 4.42861C10 4.55663 10 4.70442 10 5"
      stroke="currentColor"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>
