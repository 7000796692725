import actions from './actions.wallet.store';
import mutations from './mutations.wallet.store';
import state from './state.wallet.store';
import * as getters from './getters.wallet.store';

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};
