<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="30"
    height="26"
    viewBox="0 0 30 26"
  >
    <path
      id="前面オブジェクトで型抜き_5"
      data-name="前面オブジェクトで型抜き 5"
      d="M1410.424-89.683h-22.3a2.159,2.159,0,0,1-2.145-2.167v-21.242a2.623,2.623,0,0,1,2.6-2.591h19.043a2.158,2.158,0,0,1,2.141,2.159l-.006,1.609h.654a3.205,3.205,0,0,1,3.176,3.207v4.33h.715a1.7,1.7,0,0,1,1.677,1.689v3.773a1.7,1.7,0,0,1-1.672,1.688h-.715v4.335A3.2,3.2,0,0,1,1410.424-89.683Zm-3.341-13.21a.207.207,0,0,0-.2.2v3.783a.2.2,0,0,0,.191.189h7.24a.187.187,0,0,0,.135-.059.2.2,0,0,0,.051-.14h.006v-3.769a.206.206,0,0,0-.2-.2Zm-18.5-11.3a1.143,1.143,0,0,0-1.123,1.165,1.149,1.149,0,0,0,.323.778,1.121,1.121,0,0,0,.8.336h19.7l.01-1.614a.669.669,0,0,0-.664-.666Z"
      transform="translate(-1385.983 115.683)"
      fill="#d72500"
    />
  </svg>
</template>

<script>
export default {
  name: 'wallet-icon',
};
</script>

<style></style>
