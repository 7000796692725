<template>
  <a-drawer
    width="300"
    placement="right"
    :visible="drawerVisible"
    @close="$emit('onClose')"
    class="wallet-drawer"
  >
    <template #title>
      <router-link
        to="/"
        class="drawer-logo"
      >
        <img
          src="/images/logo.png"
          alt="Matsuri NFT"
        />
      </router-link>
    </template>

    <div class="flex items-center justify-center flex-col mt-8 space-y-4">
      <LoginButton :is_show_nav_user="true" />
      <router-link
        to="/about"
        class="flex text-black items-center mr-4"
      >
        <img
          style="width: 30px"
          src="/icons/global-icon.png"
          alt="Global"
        />
        <span class="ml-2">{{ $t('ABOUT_MATSURI_NFT') }}</span>
      </router-link>
      <LocaleSwitch></LocaleSwitch>
    </div>
  </a-drawer>
</template>

<script>
import LocaleSwitch from '@/components/common/LocaleSwitch.vue';
import LoginButton from '@/components/common/LoginButton.vue';
export default {
  components: {
    LocaleSwitch,
    LoginButton,
  },
  props: {
    drawerVisible: { type: Boolean, default: false },
    isHomePage: { type: Boolean, default: false },
  },
  watch: {
    $route() {
      this.$emit('onClose');
    },
  },
};
</script>
<style lang="scss">
.wallet-drawer {
  .ant-drawer-header-title {
    .ant-drawer-close {
      order: 2;
      margin-right: 0;
    }
    .ant-drawer-title {
      order: 1;
    }
  }
  .router-link-exact-active {
    color: #d72500;
  }
  .ant-drawer-content {
    @apply bg-white;
    .ant-drawer-close {
      color: #d72500;
    }
    .ant-drawer-body {
      @apply p-0;
    }
  }
  .ant-drawer-title {
    line-height: 16px;
  }
  .drawer-logo {
    width: 100px;
    height: auto;
    object-fit: contain;
    display: inline-block;
  }
}
</style>
