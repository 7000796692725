export const CHAIN_ID = {
  ETH_MAINNET: 1,
  SEPOLIA_TESTNET: 11155111,
  POLYGON_TESTNET: 80001,
  POLYGON_MAINNET: 137,
  BINANCE_TESTNET: 97,
  BINANCE_MAINNET: 56,
  ASTAR_SHIBUYA_TESTNET: 81,
  ASTAR_MAINNET: 592,
  AVAX_MAINNET: 43114,
  AVAX_TESTNET: 43113,
  POLYGON_AMOY: 80002,
};

export const TOKEN_ADDRESSES = {
  [CHAIN_ID.POLYGON_TESTNET]: {
    USDC: '0xCE2DB1ab44FDe62A7aC9d27E64c58F90eff0e573',
  },
  [CHAIN_ID.POLYGON_MAINNET]: {
    USDC: '0x2791Bca1f2de4661ED88A30C99A7a9449Aa84174',
  },
  [CHAIN_ID.ETH_MAINNET]: {
    USDC: '0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48',
  },
  [CHAIN_ID.SEPOLIA_TESTNET]: {
    USDC: '0xE22c30Da8f1CBad4961756bD5A7156d5f2e66fa7',
  },
  [CHAIN_ID.AVAX_MAINNET]: {
    USDC: '0xb97ef9ef8734c71904d8002f8b6bc66dd9c48a6e',
  },
  [CHAIN_ID.AVAX_TESTNET]: {
    USDC: '0xE22c30Da8f1CBad4961756bD5A7156d5f2e66fa7',
  },
  [CHAIN_ID.BINANCE_MAINNET]: {
    USDC: '0x8ac76a51cc950d9822d68b83fe1ad97b32cd580d',
  },
  [CHAIN_ID.BINANCE_TESTNET]: {
    USDC: '0xE22c30Da8f1CBad4961756bD5A7156d5f2e66fa7',
  },
  [CHAIN_ID.ASTAR_MAINNET]: {
    USDC: '0x6a2d262d56735dba19dd70682b39f6be9a931d98',
  },
  [CHAIN_ID.ASTAR_SHIBUYA_TESTNET]: {
    USDC: '0x618573D1278e028E62814750B9Df7D265Aa9c35e',
  },
};

export const TOKEN_SYMBOLS = {
  [CHAIN_ID.POLYGON_TESTNET]: {
    [TOKEN_ADDRESSES[CHAIN_ID.POLYGON_TESTNET]['USDC']]: 'USDC',
  },
  [CHAIN_ID.POLYGON_MAINNET]: {
    [TOKEN_ADDRESSES[CHAIN_ID.POLYGON_MAINNET]['USDC']]: 'USDC',
  },
  [CHAIN_ID.ETH_MAINNET]: {
    [TOKEN_ADDRESSES[CHAIN_ID.ETH_MAINNET]['USDC']]: 'USDC',
  },
  [CHAIN_ID.SEPOLIA_TESTNET]: {
    [TOKEN_ADDRESSES[CHAIN_ID.SEPOLIA_TESTNET]['USDC']]: 'USDC',
  },
  [CHAIN_ID.AVAX_MAINNET]: {
    [TOKEN_ADDRESSES[CHAIN_ID.AVAX_MAINNET]['USDC']]: 'USDC',
  },
  [CHAIN_ID.AVAX_TESTNET]: {
    [TOKEN_ADDRESSES[CHAIN_ID.AVAX_TESTNET]['USDC']]: 'USDC',
  },
  [CHAIN_ID.BINANCE_MAINNET]: {
    [TOKEN_ADDRESSES[CHAIN_ID.BINANCE_MAINNET]['USDC']]: 'USDC',
  },
  [CHAIN_ID.BINANCE_TESTNET]: {
    [TOKEN_ADDRESSES[CHAIN_ID.BINANCE_TESTNET]['USDC']]: 'USDC',
  },
  [CHAIN_ID.ASTAR_MAINNET]: {
    [TOKEN_ADDRESSES[CHAIN_ID.ASTAR_MAINNET]['USDC']]: 'USDC',
  },
  [CHAIN_ID.ASTAR_SHIBUYA_TESTNET]: {
    [TOKEN_ADDRESSES[CHAIN_ID.ASTAR_SHIBUYA_TESTNET]['USDC']]: 'USDC',
  },
};

export const ZERO_FEE_CHAINS = process.env.VUE_APP_ZERO_FEE_CHAIN_IDS
  ? process.env.VUE_APP_ZERO_FEE_CHAIN_IDS.split(',').map((item) => item.replace(' ', ''))
  : [];

export const CHAIN_INFO = {
  [CHAIN_ID.SEPOLIA_TESTNET]: {
    host: 'https://sepolia.infura.io/v3/8766da5caa9b4f90aab4b33031390558',
    explorer: 'https://sepolia.etherscan.io',
    name: 'Sepolia Testnet',
    logo: '/images/coin/eth.png',
    nativeCurrency: {
      name: 'Ethereum',
      symbol: 'ETH',
      decimals: 18,
    },
    acceptedTokens: [
      {
        name: 'USD Coin',
        symbol: 'USDC',
        decimals: 6,
        tokenAddress: TOKEN_ADDRESSES[CHAIN_ID.SEPOLIA_TESTNET]['USDC'],
      },
    ],
    nft721Symbol: 'ERC721',
    rpcUrl: 'https://sepolia.infura.io/v3/8766da5caa9b4f90aab4b33031390558',
  },
  [CHAIN_ID.ETH_MAINNET]: {
    host: 'mainnet',
    explorer: 'https://etherscan.io',
    name: 'Ethereum',
    logo: '/images/coin/eth.png',
    nativeCurrency: {
      name: 'Ethereum',
      symbol: 'ETH',
      decimals: 18,
    },
    acceptedTokens: [
      {
        name: 'USD Coin',
        symbol: 'USDC',
        decimals: 6,
        tokenAddress: TOKEN_ADDRESSES[CHAIN_ID.ETH_MAINNET]['USDC'],
      },
    ],
    nft721Symbol: 'ERC721',

    rpcUrl: 'https://mainnet.infura.io/v3/c49d59b174b6428e833462caf3c57df7',
  },
  [CHAIN_ID.POLYGON_TESTNET]: {
    host: 'mumbai',
    explorer: 'https://mumbai.polygonscan.com',
    name: 'Polygon Mumbai Testnet',
    logo: '/images/coin/matic.png',
    nativeCurrency: {
      name: 'Matic',
      symbol: 'MATIC',
      decimals: 18,
    },
    acceptedTokens: [
      {
        name: 'USD Coin',
        symbol: 'USDC',
        decimals: 6,
        tokenAddress: TOKEN_ADDRESSES[CHAIN_ID.POLYGON_TESTNET]['USDC'],
      },
    ],
    nft721Symbol: 'ERC721',

    rpcUrl: 'https://rpc-mumbai.maticvigil.com',
  },
  [CHAIN_ID.POLYGON_AMOY]: {
    explorer: 'https://amoy.polygonscan.com/',
    name: 'Polygon Amoy Testnet',
    logo: '/images/coin/matic.png',
    nativeCurrency: {
      name: 'Matic',
      symbol: 'MATIC',
      decimals: 18,
    },
    acceptedTokens: [],
    nft721Symbol: 'ERC721',
    rpcUrl: 'https://rpc-amoy.polygon.technology',
    isMetaTxEnabled: false,
  },
  [CHAIN_ID.POLYGON_MAINNET]: {
    host: 'matic',
    explorer: 'https://polygonscan.com',
    name: 'Polygon',
    logo: '/images/coin/matic.png',
    nativeCurrency: {
      name: 'Matic',
      symbol: 'MATIC',
      decimals: 18,
    },
    acceptedTokens: [
      {
        name: 'USD Coin',
        symbol: 'USDC',
        decimals: 6,
        tokenAddress: TOKEN_ADDRESSES[CHAIN_ID.POLYGON_MAINNET]['USDC'],
      },
    ],
    nft721Symbol: 'ERC721',

    rpcUrl: 'https://polygon-rpc.com',
  },
  [CHAIN_ID.BINANCE_TESTNET]: {
    explorer: 'https://testnet.bscscan.com',
    name: 'BSC Network Testnet',
    logo: '/images/coin/bnb.png',
    nativeCurrency: {
      name: 'Binance Coin',
      symbol: 'BNB',
      decimals: 18,
    },
    acceptedTokens: [
      {
        name: 'USD Coin',
        symbol: 'USDC',
        decimals: 6,
        tokenAddress: TOKEN_ADDRESSES[CHAIN_ID.BINANCE_TESTNET]['USDC'],
      },
    ],
    nft721Symbol: 'BEP721',

    rpcUrl: 'https://bsc-testnet.publicnode.com',
  },
  [CHAIN_ID.BINANCE_MAINNET]: {
    explorer: 'https://bscscan.com',
    name: 'BSC Network',
    logo: '/images/coin/bnb.png',
    nativeCurrency: {
      name: 'Binance Coin',
      symbol: 'BNB',
      decimals: 18,
    },
    acceptedTokens: [
      {
        name: 'USD Coin',
        symbol: 'USDC',
        decimals: 6,
        tokenAddress: TOKEN_ADDRESSES[CHAIN_ID.BINANCE_MAINNET]['USDC'],
      },
    ],
    nft721Symbol: 'BEP721',

    rpcUrl: 'https://bsc-dataseed1.binance.org/',
  },
  [CHAIN_ID.ASTAR_SHIBUYA_TESTNET]: {
    explorer: 'https://blockscout.com/shibuya',
    name: 'Astar Shibuya Testnet',
    logo: '/images/coin/astr.png',
    nativeCurrency: {
      name: 'Shibuya',
      symbol: 'SBY',
      decimals: 18,
    },
    acceptedTokens: [
      {
        name: 'USD Coin',
        symbol: 'USDC',
        decimals: 6,
        tokenAddress: TOKEN_ADDRESSES[CHAIN_ID.ASTAR_SHIBUYA_TESTNET]['USDC'],
      },
    ],
    nft721Symbol: 'ERC721',

    rpcUrl: 'https://evm.shibuya.astar.network',
  },
  [CHAIN_ID.ASTAR_MAINNET]: {
    explorer: 'https://blockscout.com/astar',
    name: 'Astar Mainnet',
    logo: '/images/coin/astr.png',
    nativeCurrency: {
      name: 'Astar',
      symbol: 'ASTR',
      decimals: 18,
    },
    acceptedTokens: [
      {
        name: 'USD Coin',
        symbol: 'USDC',
        decimals: 6,
        tokenAddress: TOKEN_ADDRESSES[CHAIN_ID.ASTAR_MAINNET]['USDC'],
      },
    ],
    nft721Symbol: 'ERC721',

    rpcUrl: 'https://evm.astar.network',
  },
  [CHAIN_ID.AVAX_MAINNET]: {
    explorer: 'https://snowtrace.io',
    name: 'Avalance C-Chain',
    logo: '/images/coin/avax.png',
    nativeCurrency: {
      name: 'Avalance',
      symbol: 'AVAX',
      decimals: 18,
    },
    acceptedTokens: [
      {
        name: 'USD Coin',
        symbol: 'USDC',
        decimals: 6,
        tokenAddress: TOKEN_ADDRESSES[CHAIN_ID.AVAX_MAINNET]['USDC'],
      },
    ],
    nft721Symbol: 'ERC721',

    rpcUrl: 'https://api.avax.network/ext/bc/C/rpc',
  },
  [CHAIN_ID.AVAX_TESTNET]: {
    explorer: 'https://testnet.snowtrace.io',
    name: 'Avalanche FUJI C-Chain',
    logo: '/images/coin/avax.png',
    nativeCurrency: {
      name: 'Avalance',
      symbol: 'AVAX',
      decimals: 18,
    },
    acceptedTokens: [
      {
        name: 'USD Coin',
        symbol: 'USDC',
        decimals: 6,
        tokenAddress: TOKEN_ADDRESSES[CHAIN_ID.AVAX_TESTNET]['USDC'],
      },
    ],
    nft721Symbol: 'ERC721',

    rpcUrl: 'https://api.avax-test.network/ext/bc/C/rpc',
  },
};

export const SUPPORTED_CHAIN_IDS = process.env.VUE_APP_SUPPORTED_CHAIN_IDS.split(',').map((el) => {
  const newEl = el.replace(' ', '');
  return Number(newEl);
});
