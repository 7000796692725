import { notify } from '@kyvg/vue3-notification';
import ja_json from '@/lang/ja.json';

export function formatError(error) {
  if (error.code && error.code == 4001) {
    return 'TX_MODAL_REJECT_TEXT';
  }
  if (error && (typeof error === 'string' || error instanceof String)) {
    return error;
  }
  if (error.code && ja_json.error_code_be[error.code]) {
    return ja_json.error_code_be[error.code];
  }
  if (error.message) {
    return error.message;
  }
  return 'Undefined Error';
}

export const error = (t, error) => {
  return notify({
    type: 'error',
    title: error.title ? t(error.title) : t('NOTIFICATION_ERROR'),
    text: t(formatError(error)),
  });
};

export const success = (t, success) => {
  return notify({
    type: 'success',
    title: success.title ? t(success.title) : t('NOTIFICATION_SUCCESS'),
    text: t(success.message) || success,
  });
};

export default {
  error,
  success,
  formatError,
};
