import { CHAIN_INFO } from '@/constants/chains.constant';
import { WC_CHAIN_IDS, WC_OPTIONAL_CHAIN_IDS } from '@/env';
import { EthereumProvider } from '@walletconnect/ethereum-provider';
import { CoreUtil } from '@walletconnect/modal-core';

class MetamaskMobileConnector {
  /**
   * @type {import('@walletconnect/ethereum-provider').default}
   */
  provider = null;
  constructor() {}

  getProvider() {
    if (!this.provider) {
      return null;
    }
    return this.provider;
  }

  async setup() {
    const chains = WC_CHAIN_IDS;
    const optionalChains = WC_OPTIONAL_CHAIN_IDS;
    const rpcMap = {};
    Object.entries(CHAIN_INFO).forEach(([key, value]) => {
      rpcMap[key] = value.rpcUrl;
    });

    this.provider = await EthereumProvider.init({
      projectId: 'dc6e9160fcd5d1c9b3d72ca2bf785393',
      chains,
      optionalChains,
      rpcMap,
      showQrModal: false,
    });
  }

  async connect(callback) {
    this.provider.on('display_uri', async (uri) => {
      const deepLinkUri = CoreUtil.formatNativeUrl('metamask://', uri, 'metamask');
      if (CoreUtil.isMobile()) {
        window.open(deepLinkUri, '_self', 'noreferrer noopener');
      }
    });
    // eslint-disable-next-line no-async-promise-executor
    this.provider.on('connect', async (info) => {
      if (callback) {
        await callback(info);
      }
    });
    await this.provider.connect();
  }

  async disconnect() {
    try {
      if (this.provider) {
        CoreUtil.removeWalletConnectDeepLink();
        if (this.provider.connected) {
          await this.provider.disconnect();
        }
      }
    } catch (err) {
      console.error(err);
    } finally {
      window.localStorage.removeItem('MATSURI_CONNECTOR');
    }
  }

  toHex(chainIdDec) {
    const chainInHex = `0x${Number(chainIdDec).toString(16)}`;
    return chainInHex;
  }

  async switchChain(chainId) {
    const provider = this.getProvider();
    await provider.request({
      method: 'wallet_switchEthereumChain',
      params: [{ chainId: this.toHex(chainId) }],
    });
  }

  async addChain({ explorer, name, nativeCurrency, rpcUrl, chain }) {
    const provider = this.getProvider();
    await provider.request({
      method: 'wallet_addEthereumChain',
      params: [
        {
          chainId: this.toHex(chain),
          chainName: name,
          nativeCurrency: {
            ...nativeCurrency,
          },
          rpcUrls: [rpcUrl],
          blockExplorerUrls: [explorer],
        },
      ],
    });
  }

  subscribeToEvents(chainChangeCb, accountChangeCb, disconnectCb) {
    const provider = this.provider;
    if (!provider) return;

    provider.on('accountsChanged', (accounts) => {
      if (accounts.length) {
        if (accountChangeCb) {
          accountChangeCb(accounts[0]);
        }
      } else {
        if (disconnectCb) {
          console.log('no account availabe, disconnecting...');
          disconnectCb();
        }
      }
    });

    provider.on('chainChanged', (networkId) => {
      const chainId = Number(networkId);
      if (!chainId || isNaN(chainId)) {
        if (disconnectCb) {
          disconnectCb();
        }
      } else {
        if (chainChangeCb) {
          chainChangeCb(chainId);
        }
      }
    });

    provider.on('disconnect', (error) => {
      console.error(error);
      disconnectCb();
    });
  }
}

export default new MetamaskMobileConnector();
