<template>
  <a-select
    class="w-16 lang-select"
    v-model:value="locale"
    @change="switchLocale"
    default-value="ja"
  >
    <a-select-option value="ja">jp</a-select-option>
    <a-select-option value="en">en</a-select-option>
  </a-select>
</template>

<script>
import { mapActions, mapState } from 'vuex';
export default {
  computed: {
    ...mapState({
      locale: (state) => state?.setting.locale || 'ja',
    }),
  },
  created() {
    // this.switchLocale('ja');
  },
  methods: {
    ...mapActions({
      changeLocale: 'setting/changeLocale',
    }),
    switchLocale(locale) {
      this.changeLocale(locale);
    },
  },
};
</script>

<style lang="scss">
.lang-select {
  > div {
    border: none !important;
    box-shadow: none !important;
  }

  .ant-select-selection-item {
    font-weight: 600;
  }
}
</style>
