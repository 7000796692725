<template>
  <a-select
    ref="select"
    :value="modelValue"
    style="width: 120px"
    @change="$emit('update:modelValue', $event)"
    :placeholder="$t('CATEGORY')"
  >
    <a-select-option
      :value="category.id"
      v-bind:key="category.id"
      v-for="category in data?.items"
    >
      {{ category.name }}
    </a-select-option>

  </a-select>
</template>

<script>
import { getCategories } from '@/services';
import { useQuery } from 'vue-query';

export default {
  name: 'category-dropdown',
  setup() {
    const { data } = useQuery(['categories'], () => getCategories({ limit: 100 }));
    return {
      data,
    };
  },
  props: ['modelValue'],
  emits: ['update:modelValue'],
};
</script>
