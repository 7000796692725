<template>
  <div class="wp-logined">
    <a-tooltip
      :visible="visibleTooltip"
      placement="bottomLeft"
      overlayClassName="tooltip-user-avatar"
      :getPopupContainer="(triggerNode) => triggerNode.parentNode"
    >
      <template #title>
        <div class="tooltip-main-content">
          <div class="user-profile">
            <div class="user-info">
              <div class="user-name">
                {{ account }}
              </div>
            </div>
          </div>
          <div class="user-navigation">
            <ul class="user-nav-links">
              <li
                class="user-link"
                @click="gotoManagement()"
              >
                {{ $t('NAV_MANAGEMENT') }}
              </li>
              <li
                class="user-link"
                @click="gotoMyProfile()"
              >
                {{ $t('NAV_MY_PAGE') }}
              </li>
            </ul>
          </div>
          <div class="user-navigation logout-container">
            <ul class="user-nav-links">
              <li
                class="user-link"
                @click="disconnect"
              >
                <IconLogout class="user-nav-icon" />{{ $t('DISCONNECT') }}
              </li>
            </ul>
          </div>
        </div>
      </template>
      <button
        v-if="account"
        @click.prevent="changeVisiblility"
        class="img-avatar"
      >
        <img
          src="/images/user.png"
          alt="user"
        />
        <caret-down-outlined />
      </button>
    </a-tooltip>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import IconLogout from '@/components/icons/logout-icon.vue';
// import { onMounted, beforeUnmount } from 'vue';
export default {
  components: {
    IconLogout,
  },
  data() {
    return {
      visibleTooltip: false,
    };
  },
  computed: {
    ...mapState({
      userInfo: (state) => state.wallet?.userInfo || {},
    }),
    ...mapGetters({
      isAdmin: 'wallet/isAdmin',
      account: 'wallet/account',
    }),
  },
  mounted() {
    document.addEventListener('click', this.handleDocumentClick);
  },
  beforeUnmount() {
    if (process.client) {
      document.removeEventListener('click', this.handleDocumentClick);
    }
  },
  methods: {
    handleDocumentClick(event) {
      if (!this.$el.contains(event.target)) {
        this.visibleTooltip = false;
      }
    },
    changeVisiblility() {
      this.visibleTooltip = !this.visibleTooltip;
    },
    // desktop only
    toggleTooltip(status) {
      if (window.innerWidth > 767) {
        this.visibleTooltip = status;
      }
    },
    gotoMyProfile() {
      this.changeVisiblility();
      this.$router.push('/my-page');
    },
    gotoManagement() {
      this.changeVisiblility();
      this.$router.push('/manage/projects');
    },
    disconnect() {
      this.changeVisiblility();
      this.$emit('disconnect');
    },
  },
};
</script>
<style lang="scss">
.img-avatar img {
  width: 30px;
  aspect-ratio: 1;
  border-radius: 50%;
}
.tooltip-user-avatar.ant-tooltip {
  max-width: 280px;
  // top: 32px !important;
  .ant-tooltip-content {
    .ant-tooltip-arrow {
      display: none;
    }
    .ant-tooltip-inner {
      border-radius: 8px;
      border: 1px solid #ebebf0;
      box-shadow: 0px 4px 8px rgba(40, 40, 43, 0.1);
      background: #ffffff;
      font-style: normal;
      font-weight: 500;
      font-size: 13px;
      line-height: 18px;
      color: #808089;
      padding: 0px;
      .tooltip-main-content {
        min-width: 280px;
        .user-profile {
          display: flex;
          // align-items: center;
          padding: 16px;
          border-bottom: 1px solid #ebebf0;
          .img-avatar {
            margin-right: 12px;
            > img {
              width: 40px;
              height: 40px;
              border-radius: 50%;
            }
          }
          .user-info {
            .user-name {
              font-style: normal;
              font-weight: 600;
              font-size: 16px;
              line-height: 24px;
              color: #38383d;
              word-break: break-all;
            }
            .user-email {
              font-style: normal;
              font-weight: 500;
              font-size: 13px;
              line-height: 18px;
              color: #808089;
              word-break: break-all;
            }
          }
        }
        .user-navigation {
          padding: 8px;
          & + .user-navigation {
            border-top: 1px solid #ebebf0;
          }
          &.logout-container {
            // border-top: 1px solid #ebebf0;
            .user-nav-links {
              .user-link {
                color: #ce624a;
              }
            }
          }
          .user-nav-links {
            list-style-type: none;
            margin: 0px;
            padding: 0px;
            .user-link {
              padding: 12px 12px;
              display: flex;
              align-items: center;
              font-style: normal;
              font-weight: 600;
              font-size: 14px;
              line-height: 20px;
              color: #38383d;
              cursor: pointer;
              &:hover {
                background: #f5f5fa;
                border-radius: 8px;
              }
              .user-nav-icon {
                margin-right: 12px;
              }
            }
          }
        }
      }
    }
  }
}
</style>
