<template>
  <div>
    <div class="default-header">
      <router-link
        to="/"
        class="default-header__logo"
      >
        <img
          class="md:block hidden"
          src="/images/logo.png"
          alt="Matsuri NFT"
        />
        <img
          class="md:hidden block w-8 h-8"
          src="/images/matsuri-logo-no-text.svg"
          alt="Matsuri NFT"
        />
      </router-link>
      <div class="ml-2 sm:ml-10 items-center justify-between w-full flex">
        <a-input-group
          style="width: unset"
          compact
        >
          <CategoryDropdown
            class="dropdown-btn"
            v-model="category"
          />

          <a-input-search
            class="search-input"
            :placeholder="$t('SEARCH')"
            @search="handleSearch"
          />
        </a-input-group>
        <div class="items-center hidden md:flex lg:flex xl:flex ml-4">
          <router-link
            to="/about"
            class="flex items-center mr-4"
          >
            <img
              style="width: 30px"
              src="/icons/global-icon.png"
              alt="Global"
            />
            <span class="text-black ml-2">{{ $t('ABOUT_MATSURI_NFT') }}</span>
          </router-link>
          <LocaleSwitch />
          <LoginButton
            :asIcon="true"
            :is_show_popover_user="true"
          />
        </div>
      </div>
      <div class="md:hidden ml-3 flex items-center">
        <button @click="drawerVisible = true"><BurgerIcon /></button>
      </div>
    </div>
    <NavigationHeader />
    <DefaultDrawer
      :drawerVisible="drawerVisible"
      @onClose="drawerVisible = false"
    />
  </div>
</template>

<script>
import BurgerIcon from './icons/burger-icon.vue';
import LoginButton from '@/components/common/LoginButton.vue';
import DefaultDrawer from '@/components/DefaultDrawer.vue';
import NavigationHeader from '@/components/common/NavigationHeader.vue';
import CategoryDropdown from './CategoryDropdown.vue';
import { ref } from 'vue';
import { useRouter } from 'vue-router';
import LocaleSwitch from './common/LocaleSwitch.vue';
export default {
  components: {
    LocaleSwitch,
    BurgerIcon,
    LoginButton,
    CategoryDropdown,
    DefaultDrawer,
    NavigationHeader,
  },

  setup: function () {
    const category = ref(null);
    const router = useRouter();

    const handleSearch = (text) => {
      if (!text && !category.value) return;
      router.push({
        path: '/search',
        query: { q: text, category: category.value ? category.value : undefined },
      });
    };

    const drawerVisible = ref(false);

    return {
      category,
      handleSearch,
      drawerVisible,
    };
  },
  computed: {
    isHomePage() {
      console.debug({ route: this.$route.path });
      return this.$route.path === '/' || this.$route.path === '/about';
    },
  },
  created() {},
  methods: {},
};
</script>

<style lang="scss">
.default-header {
  @apply px-4 sm:px-12 md:px-8 lg:px-18 xl:px-32;
  @apply py-4 sm:py-6;
  @apply flex items-center justify-between;
  @apply bg-white;
  @apply w-full;
  margin: 0 auto;
  max-width: 1920px;

  &__logo {
    img {
      @screen md {
        width: 90px;
      }
      height: unset;
      max-width: unset;
      object-fit: contain;
    }
  }

  .search-input {
    @apply w-full sm:w-full md:w-56 lg:w-56 xl:w-56;
    /* width: 230px !important; */
  }

  .ant-input-group.ant-input-group-compact {
    display: flex !important;
  }

  .search-input > span > span > .ant-btn {
    padding-right: 2px;
    background-color: #f5f5f5;
    border-color: #f5f5f5;
    box-shadow: none !important;
    @apply font-extralight;
  }

  .search-input > span > input {
    background-color: #f5f5f5;
    border-color: #f5f5f5;
    box-shadow: none !important;
    @apply font-extralight;
  }

  .dropdown-btn > .ant-select-selector {
    @apply px-4;
    background-color: #f5f5f5 !important;
    border: none !important;
  }
  .dropdown-btn > .ant-select-arrow {
    display: none;
  }

  .dot {
    background: hsla(0, 0%, 100%, 0.3);
    @apply flex items-center justify-center;
    &::before {
      content: '';
      display: block;
      width: 4px;
      height: 4px;
      background: #fff;
      @apply rounded-full;
    }
  }
}
</style>
