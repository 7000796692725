import { createStore } from 'vuex';
import VuexPersistence from 'vuex-persist';
import { APP_NAME, APP_VERSION, APP_ENV } from '@/env';

import wallet from './store/wallet/index.wallet.store';
import allowance from './store/allowance/index.allowance.store.js';
import projects from './store/projects/index.projects.store';
// import marketplace from './store/marketplace/index.marketplace.store';
import setting from './store/setting/index.setting.store';
const persistenceOptions = {
  key: ['vuex', APP_NAME, APP_VERSION, APP_ENV].join('-'),
  storage: window.localStorage,
};

export default createStore({
  modules: {
    wallet,
    allowance,
    projects,
    // marketplace,
    setting,
  },
  plugins: [new VuexPersistence(persistenceOptions).plugin],
});
