<template>
  <a-button class="w-full">
    <slot></slot>
  </a-button>
</template>

<script>
import { onMounted, inject, computed } from 'vue';
import Web3 from 'web3';
import { useStore } from 'vuex';
import notifyUtil from '@/utils/notify.util';
import { useI18n } from 'vue-i18n';
import { useRouter } from 'vue-router';
import { CONNECTORS } from '@/constants/types.constant';
export default {
  components: {},
  props: {
    // * connect or switch-chain
    buttonType: {
      type: String,
      default: () => 'connect',
    },
  },
  setup() {
    const store = useStore();
    const router = useRouter();
    const i18n = useI18n();
    /**
     * @type {import('@/plugins/connector-manager').default}
     */
    const connectorManager = inject('authConnector');

    /**
     * @type {import('@/plugins/upbond-connector').default}
     */
    const connector = inject('upbondConnector');
    connector.upbond.isLoginCallback = async () => {
      try {
        store.commit('setting/CHANGE_GLOBAL_LOADING', true);
        if (!connector.upbond.isLoggedIn) return;
        connector.getProvider().removeAllListeners();
        connector.onAccountsChange(onAccountChange);
        connector.onChainChange(onChainChange);
        const web3Provider = connector.getProvider();
        const userInfo = await connector.getUserInfo();
        const web3 = new Web3(web3Provider);
        const chainId = await web3.eth.getChainId();
        const accounts = await web3.eth.getAccounts();
        const provider = {
          chainId: chainId,
          address: accounts?.length ? accounts[0] : '',
        };
        store.commit('wallet/UPDATE_WALLET', { provider, userInfo });
        store.dispatch('wallet/loginWithAddress', provider.address);
        connectorManager.setConnector(connector);
        window.localStorage.setItem('MATSURI_CONNECTOR', CONNECTORS.UPBOND);
        const path = window.localStorage.getItem('UPBOND_REDIRECTED_TARGET');
        if (path) {
          window.localStorage.removeItem('UPBOND_REDIRECTED_TARGET');
          router.push(path);
        }
      } catch (err) {
        notifyUtil.error(i18n.t, err);
      } finally {
        store.commit('setting/CHANGE_GLOBAL_LOADING', false);
      }
    };

    async function connectWallet() {
      try {
        await disconnect();
        do {
          window.localStorage.setItem(
            'UPBOND_REDIRECTED_TARGET',
            `${window.location.pathname}${window.location.search}`
          );
          await new Promise((resolve) => setTimeout(resolve, 1000));
        } while (!window.localStorage.getItem('UPBOND_REDIRECTED_TARGET'));
        await connector.connect();
      } catch (err) {
        notifyUtil.error(i18n.t, err);
      }
    }

    async function onAccountChange(account) {
      if (!account) {
        await disconnect();
        return;
      }
      store.commit('wallet/UPDATE_WALLET', {
        provider: {
          address: account,
        },
      });
      store.dispatch('wallet/loginWithAddress', account);
    }

    async function onChainChange(chainId) {
      if (!chainId) {
        await disconnect();
        return;
      }
      const web3Provider = connector.getProvider();
      const web3 = new Web3(web3Provider);
      const _chainId = await web3.eth.getChainId();
      if (chainId !== _chainId) {
        location.reload();
        return;
      }
      store.commit('wallet/UPDATE_WALLET', {
        provider: {
          chainId: _chainId,
        },
      });
    }

    async function disconnect() {
      try {
        await connector.disconnect();
      } catch (err) {
        // do nothing
      }
      store.commit('wallet/RESET_WALLET_STATE');
      localStorage.removeItem('MATSURI_CONNECTOR');
    }

    const locale = computed(() => store.state?.setting?.locale || 'ja');
    onMounted(async () => {
      if (!connector?.upbond?.isInitialized) {
        console.log('upbond not intitialized');
        await connector.setup(store.state?.setting?.locale || 'ja');
      }
      console.log('upbond-button intialized');
    });

    return {
      connectWallet,
    };
  },
};
</script>

<style scss></style>
