<template>
  <LocaleProvider>
    <a-spin :spinning="loading">
      <main class="main">
        <upbond-button v-show="false"></upbond-button>
        <metamask-button v-show="false"></metamask-button>
        <wc-button v-show="false"></wc-button>
        <metamask-mobile-button v-show="false"></metamask-mobile-button>
        <DefaultHeader />
        <div class="w-full wrapper">
          <div class="wrapper-content">
            <router-view />
          </div>
        </div>
        <DefaultFooter :class="[{ 'footer-page': $route?.fullPath !== '/' }]" />
        <BackToTop />
        <notifications />
      </main>
    </a-spin>
  </LocaleProvider>
</template>

<script>
import { computed, defineComponent, onMounted } from 'vue';
import DefaultHeader from '@/components/DefaultHeader.vue';
import DefaultFooter from '@/components/DefaultFooter.vue';
import BackToTop from '@/components/common/BackToTop.vue';
import { configure } from 'vee-validate';
import { useI18n } from 'vue-i18n';
import LocaleProvider from './components/common/LocaleProvider.vue';
import { useStore } from 'vuex';
import WcButton from '@/components/common/WcButton.vue';
import MetamaskButton from '@/components/common/MetamaskButton.vue';
import UpbondButton from '@/components/common/UpbondButton.vue';
import MetamaskMobileButton from './components/common/MetamaskMobileButton.vue';
export default defineComponent({
  name: 'app',
  setup() {
    const i18n = useI18n();
    const store = useStore();
    configure({
      generateMessage: (context) => {
        const { field, rule } = context;
        const message = i18n.t(`messages.${rule.name}`, {
          _field_: i18n.t(`fields.${field}`),
          min: rule.params[0],
          max: rule.params[0],
          length: rule.params[0],
          size: rule.params[0],
        });
        return message;
      },
    });
    const loading = computed(() => {
      return store.state?.setting?.globalLoading || false;
    });
    onMounted(() => {
      store.commit('setting/CHANGE_GLOBAL_LOADING', false);
    });
    return {
      loading,
    };
  },
  components: {
    DefaultHeader,
    DefaultFooter,
    BackToTop,
    LocaleProvider,
    UpbondButton,
    MetamaskButton,
    WcButton,
    MetamaskMobileButton,
  },
  methods: {},
});
</script>

<style lang="scss">
#app {
  @apply relative;
  font-family: 'Noto Sans JP', sans-serif;
  @apply text-sm;
  // background-color: #fff;
  // .img-layer {
  //   @apply absolute object-contain  pointer-events-none;
  // }
  .main {
    min-height: 100vh;
    width: 100%;
    @apply mx-auto;
    position: relative;
    display: flex;
    flex-direction: column;
    .menu {
      width: 245px;
    }
    .wrapper {
      width: 100%;
      flex-grow: 1;
      position: relative;
      display: flex;
      flex-direction: column;
      background-size: 100%;
      position: relative;
      .wrapper-content {
        width: 100%;
        flex-grow: 1;
        position: relative;
        z-index: 1;
      }
    }
    .footer-page .default-footer {
      border-top: 1px solid #e4e4e4;
      margin-top: 120px;
    }
  }
}
</style>
