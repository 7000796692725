import http from "./axios";
import { API_VERSION_1_PREFIX } from "@/env";

export const getMarketplaceList = async ({
  limit = 6,
  offset = 0,
  name = "",
  orderBy = "created_at",
  status = undefined,
  sortOrder = "desc",
}) => {
  try {
    const res = await http.get(`${API_VERSION_1_PREFIX}/marketplace/projects`, {
      params: {
        limit,
        offset,
        name,
        order_by: orderBy,
        status,
        sort_order: sortOrder,
      },
    });
    return res;
  } catch (error) {
    console.log(error);
    return null;
  }
};
