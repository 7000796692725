import { CONNECTORS } from '@/constants/types.constant';
import { inject, onMounted } from 'vue';
import { useStore } from 'vuex';
import Web3 from 'web3';
import notifyUtil from '@/utils/notify.util';
import { useI18n } from 'vue-i18n';

export default function useConnectorWatcher(connectorName) {
  const connector = inject(convertNameToInjectKey(connectorName));
  const connectorManager = inject('authConnector');
  const store = useStore();
  const i18n = useI18n();

  function convertNameToInjectKey(connectorName) {
    switch (connectorName) {
      case CONNECTORS.METAMASK:
        return 'metamaskConnector';
      case CONNECTORS.WALLET_CONNECT:
        return 'wcConnector';
      case CONNECTORS.METAMASK_MOBILE:
        return 'metamaskMobileConnector';
      default:
        return null;
    }
  }

  onMounted(() => {
    listeningToEvents();
  });
  function listeningToEvents() {
    if (window.localStorage.getItem('MATSURI_CONNECTOR') !== connectorName) return;
    connectorManager.setConnector(connector);
    connector.subscribeToEvents(onChainChange, onAccountChange, onDisconnect);
  }

  async function updateUserInfo(web3Provider) {
    const web3 = new Web3(web3Provider);
    const _chainId = await web3.eth.getChainId();
    const accounts = await web3.eth.getAccounts();
    const provider = {
      chainId: _chainId,
      address: accounts?.length ? accounts[0] : '',
    };
    store.commit('wallet/UPDATE_WALLET', { provider, userInfo: {} });
    store.dispatch('wallet/loginWithAddress', provider.address);
    window.localStorage.setItem('MATSURI_CONNECTOR', connectorName);
  }

  async function onDisconnect() {
    try {
      await connector.disconnect();
    } catch (err) {
      // do nothing
    }
    store.commit('wallet/RESET_WALLET_STATE');
    window.localStorage.removeItem('MATSURI_CONNECTOR');
  }

  async function onAccountChange(account) {
    store.commit('wallet/UPDATE_WALLET', {
      provider: {
        address: account,
      },
    });
    store.dispatch('wallet/loginWithAddress', account);
  }

  async function onChainChange(chainId) {
    try {
      const provider = connectorManager.getProvider();
      const web3 = new Web3(provider);
      const _chainId = await web3.eth.getChainId();
      store.commit('wallet/UPDATE_WALLET', {
        provider: {
          chainId: _chainId,
        },
      });
      if (Number(_chainId) !== Number(chainId)) {
        throw new Error('Provider acting weird');
      }
    } catch (err) {
      notifyUtil.error(i18n.t, {
        message: 'Error on switching chain, please switch chain manually if possible!',
      });
    }
  }

  return {
    listeningToEvents,
    onDisconnect,
    onAccountChange,
    onChainChange,
    updateUserInfo,
  };
}
