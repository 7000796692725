import { CHAIN_ID } from './chains.constant';

export const contracts = {
  projectManagement: {
    [CHAIN_ID.SEPOLIA_TESTNET]: '0x8b2df5D471C4aCb3bDBDC6CB90e96148105ab73F',
    [CHAIN_ID.POLYGON_TESTNET]: '0xc77a382b360E82b1c91759803730DB7D1E1C5d05',
    [CHAIN_ID.POLYGON_AMOY]: '0x35b89AA230036d0136aA0F733CFba0604F5Fda1E',
    [CHAIN_ID.ETH_MAINNET]: '0x53bF27D2d1C28EFa19d59AE62B23A32224F6e249',
    [CHAIN_ID.POLYGON_MAINNET]: '0x53bF27D2d1C28EFa19d59AE62B23A32224F6e249',
    [CHAIN_ID.ASTAR_SHIBUYA_TESTNET]: '0xdd94bAC4b439b380130a2a691057406d941Fa19c',
    [CHAIN_ID.ASTAR_MAINNET]: '0x53bF27D2d1C28EFa19d59AE62B23A32224F6e249',
    [CHAIN_ID.BINANCE_TESTNET]: '0x976E7E1A87AE6355eD87E664Bb22926e33F62fd2',
    [CHAIN_ID.BINANCE_MAINNET]: '0x53bF27D2d1C28EFa19d59AE62B23A32224F6e249',
    [CHAIN_ID.AVAX_TESTNET]: '0x19765309dAEFa2f1d828129881CA3080708CFbB2',
    [CHAIN_ID.AVAX_MAINNET]: '0x53bF27D2d1C28EFa19d59AE62B23A32224F6e249',
  },
  externalVault: {
    [CHAIN_ID.SEPOLIA_TESTNET]: '0xb9803c12Bc531f173585242fE300cB76541796c9',
    [CHAIN_ID.POLYGON_TESTNET]: '0xA238a0A0dCeA35481dBCFb222C64Ced1C462bcB8',
    [CHAIN_ID.POLYGON_AMOY]: '0xa930893A934D4d1Ed223f8B25b26A06E4070Fd2f',
    [CHAIN_ID.ETH_MAINNET]: '0xcb9Eb9E04975D380e0b4040926C44B0d686831c2',
    [CHAIN_ID.POLYGON_MAINNET]: '0xcb9Eb9E04975D380e0b4040926C44B0d686831c2',
    [CHAIN_ID.ASTAR_SHIBUYA_TESTNET]: '0x385B872D1c0492a73E063dA657779C07f249D7C9',
    [CHAIN_ID.ASTAR_MAINNET]: '0xcb9Eb9E04975D380e0b4040926C44B0d686831c2',
    [CHAIN_ID.BINANCE_TESTNET]: '0xb1B3809E56CCEf104B93Aaf8A2c1641F5984c6Cd',
    [CHAIN_ID.BINANCE_MAINNET]: '0xcb9Eb9E04975D380e0b4040926C44B0d686831c2',
    [CHAIN_ID.AVAX_TESTNET]: '0xA1D779b0400d71b7444477a172b671c633538826',
    [CHAIN_ID.AVAX_MAINNET]: '0xcb9Eb9E04975D380e0b4040926C44B0d686831c2',
  },
  affiliateManager: {
    [CHAIN_ID.SEPOLIA_TESTNET]: '0xa5Be31499013B9F9f8B04b93e96EF570534E4DD9',
    [CHAIN_ID.POLYGON_TESTNET]: '0x64CeA3B8A1ac48Faa960dAA3af8c0Dff1E676fCA',
    [CHAIN_ID.POLYGON_AMOY]: '0x890a8403269A26aD084D1E2CaF89DC9eAe11FF1C',
    [CHAIN_ID.ETH_MAINNET]: '0x458e0ddD71b6aE780C5dBfbB51D166AB264398D5',
    [CHAIN_ID.POLYGON_MAINNET]: '0x458e0ddD71b6aE780C5dBfbB51D166AB264398D5',
    [CHAIN_ID.ASTAR_SHIBUYA_TESTNET]: '0x3d4B48a71e59d19De651E0F29bb9B251dcE65F86',
    [CHAIN_ID.ASTAR_MAINNET]: '0x458e0ddD71b6aE780C5dBfbB51D166AB264398D5',
    [CHAIN_ID.BINANCE_TESTNET]: '0xe41a57f7eC6ee998714D975D957133A73E5e1f29',
    [CHAIN_ID.BINANCE_MAINNET]: '0x458e0ddD71b6aE780C5dBfbB51D166AB264398D5',
    [CHAIN_ID.AVAX_TESTNET]: '0xD8397d6De7512f14445d58966fc3796DAF542175',
    [CHAIN_ID.AVAX_MAINNET]: '0x458e0ddD71b6aE780C5dBfbB51D166AB264398D5',
  },
};
