import Cleave from 'cleave.js';

export default (app) => {
  app.directive('cleave', {
    created: (el, binding) => {
      el.cleave = new Cleave(el, binding.value || {});
    },
    updated: (el) => {
      const event = new Event('input', { bubbles: true });
      el.value = el.cleave.properties.result;
      el.dispatchEvent(event);
    },
    unmounted: (el) => {
      el.cleave.destroy();
    },
  });
};
