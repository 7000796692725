import { CHAIN_INFO } from '@/constants/chains.constant';
import { CHAIN_ID } from '@/env';
import Upbond, { UPBOND_BUILD_ENV } from '@upbond/upbond-embed';

class UpbondConnector {
  /**
   * @type Upbond
   */
  upbond = null;
  constructor() {
    this.upbond = new Upbond();
  }

  getProvider() {
    if (!this.upbond.isLoggedIn) {
      return null;
    }
    return this.upbond?.provider;
  }

  getChainConfig(chainId = CHAIN_ID) {
    return {
      chainId: chainId,
      networkName: CHAIN_INFO[chainId].name,
      blockExplorer: CHAIN_INFO[chainId].explorer,
      ticker: CHAIN_INFO[chainId].nativeCurrency.symbol,
      tickerName: CHAIN_INFO[chainId].nativeCurrency.name,
      rpcUrl: CHAIN_INFO[chainId].rpcUrl,
      host: CHAIN_INFO[chainId].host,
    };
  }

  async setup(locale) {
    await this.upbond.init({
      buildEnv: UPBOND_BUILD_ENV.PRODUCTION,
      whiteLabel: {
        walletTheme: {
          name: 'Upbond',
          lang: locale,
          modalColor: '#f3f3f3',
          bgColor: '#214999',
          bgColorHover: '#f3f3f3',
          textColor: '#f3f3f3',
          textColorHover: '#214999',
          upbondLogin: {
            globalBgColor: '#f3f3f3',
            globalTextColor: '#214999',
          },
        },
      },
      widgetConfig: {
        showAfterLoggedIn: false,
        showBeforeLoggedIn: false,
      },
      dappRedirectUri: `${window.location.origin}/upbond-redirect`,
    });
  }

  async connect() {
    await this.upbond.login().catch((error) => console.log('on login error', error));
  }

  async disconnect() {
    if (this.upbond.isLoggedIn) {
      this.getProvider().removeAllListeners();
      await this.upbond?.logout();
      await this.upbond?.cleanUp();
      await this.setup();
    }
    window.localStorage.removeItem('MATSURI_CONNECTOR');
  }

  async switchChain(chainId) {
    if (this.upbond.isLoggedIn) {
      await this.upbond.setProvider(this.getChainConfig(chainId));
    }
  }

  onChainChange(callback) {
    if (!this.getProvider()) return;
    this.getProvider().on('chainChanged', (chainId) => {
      if (!chainId || chainId === 'loading') {
        if (callback) {
          callback(null);
        }
        return;
      }
      console.log(`User has switched to network with chain ID: ${Number(chainId)}`);
      if (callback) {
        callback(Number(chainId));
      }
    });
  }

  onAccountsChange(callback) {
    if (!this.getProvider()) return;

    this.getProvider().on('accountsChanged', (accounts) => {
      if (accounts.length && accounts[0]) {
        let account = accounts[0];
        console.log(`User has changed account: ${account}`);
        if (callback) {
          callback(account);
        }
      } else {
        console.log(`User has disconnected`);
        if (callback) {
          callback(null);
        }
      }
    });
  }

  async getUserInfo() {
    return await this.upbond.getUserInfo();
  }
}

export default new UpbondConnector();
