<template>
  <a-modal
    title=""
    :footer="null"
    wrapClassName="modal-connect"
    :visible="visible"
    @cancel="handleCancel"
    centered
    :maskStyle="{
      backgroundColor: 'rgba(255, 255, 255, 0.5)',
    }"
    :bodyStyle="{
      border: 'solid 2px #d72500',
    }"
    :style="{
      width: '320px',
    }"
  >
    <section class="title text-xl font-medium text-center">
      {{ $t('CONNECT_WALLET_TITLE') }}
    </section>
    <section class="body mb-4 mt-6 flex flex-col items-center justify-center gap-6">
      <component
        v-for="connector in connectors"
        :key="connector.id"
        @click="(e) => callConnect(connector.id)"
        :ref="connector.id"
        :is="connector.component"
        class="connect-wallet-btn"
      >
        <div class="flex flex-row items-center">
          <img
            :src="connector.logo"
            :alt="connector.name"
            class="w-7"
          />
          <div class="flex flex-1 text-center justify-center">
            {{ connector.name }}
          </div>
        </div>
      </component>
      <a
        class="hover:underline"
        target="_blank"
        href="https://note.com/matsurinft/n/nfde563b191db"
        >{{ $t('SIGN_UP_MSG') }}</a
      >
    </section>
  </a-modal>
</template>

<script>
import { CONNECTORS } from '@/constants/types.constant';
import UpbondButton from './UpbondButton.vue';
import { CHAIN_ID } from '@/env';
import MetamaskButton from './MetamaskButton.vue';
import WcButton from './WcButton.vue';
import TermConditionModal from './TermConditionModal.vue';
import MetamaskMobileButton from './MetamaskMobileButton.vue';
import { CoreUtil } from '@walletconnect/modal-core';

export default {
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    targetChain: {
      type: Number,
      default: () => CHAIN_ID,
    },
  },
  mounted() {
    this.setConnectorList();
  },
  data() {
    return {
      connectors: [],
    };
  },
  methods: {
    setConnectorList() {
      const isMobile = CoreUtil.isMobile();
      this.connectors = [
        {
          id: CONNECTORS.UPBOND,
          name: 'UPBOND',
          component: UpbondButton,
          logo: '/images/wallet/upbond.png',
        },
        {
          id: !window.ethereum && isMobile ? CONNECTORS.METAMASK_MOBILE : CONNECTORS.METAMASK,
          name: 'MetaMask',
          component: !window.ethereum && isMobile ? MetamaskMobileButton : MetamaskButton,
          logo: '/images/wallet/metamask.png',
        },
        {
          id: CONNECTORS.WALLET_CONNECT,
          name: 'WalletConnect',
          component: WcButton,
          logo: '/images/wallet/wallet-connect.png',
        },
      ];
    },
    handleCancel() {
      this.$emit('onCancel');
    },
    callConnect(id) {
      if (!this.$refs[id].length || !this.$refs[id][0]?.connectWallet) return;
      this.$refs[id][0].connectWallet(this.targetChain);
      this.handleCancel();
    },
  },
  components: { TermConditionModal },
};
</script>

<style>
.connect-wallet-btn {
  height: 46px !important;
  font-weight: 600 !important;
  max-width: 230px;
  border-radius: 9999px !important;
  border-color: #d72500 !important;
}
</style>
