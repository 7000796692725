class ConnectorManager {
  provider = null;
  connector = null;
  constructor() {}

  setConnector(connector) {
    this.connector = connector;
    this.provider = this.connector.getProvider();
  }

  getProvider() {
    return this.provider;
  }

  getConnector() {
    return this.connector;
  }
}

export default new ConnectorManager();
