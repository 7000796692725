import { UPDATE_WALLET, RESET_WALLET_STATE, UPDATE_AUTH_TOKEN } from './constants.wallet.store';
export default {
  [UPDATE_WALLET](state, { provider, userInfo }) {
    state.provider = { ...state.provider, ...provider };
    state.userInfo = { ...state.userInfo, ...userInfo };
  },
  [RESET_WALLET_STATE](state) {
    state.provider = {
      chainId: -1,
      address: '',
    };
    state.balanceTokensMap = {};
    state.userInfo = {};
    state.authToken = {};
  },
  [UPDATE_AUTH_TOKEN](state, payload) {
    state.authToken = { ...payload };
  },
};
