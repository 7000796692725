import http from './axios';
import { API_VERSION_1_PREFIX } from '@/env';
import { formatParamsApi } from '/src/utils/formatters.js';

const getShops = async (params) => {
  let query = formatParamsApi(params);
  const res = await http.get(`${API_VERSION_1_PREFIX}/shops${query}`);
  return res;
};
const getShopDetail = async (id) => {
  const res = await http.get(`${API_VERSION_1_PREFIX}/shops/${id}`);
  return res;
};
const createShop = async (data) => {
  const res = await http.post(`${API_VERSION_1_PREFIX}/shops`, data);
  return res;
};
const editShop = async (data, id) => {
  const res = await http.post(`${API_VERSION_1_PREFIX}/shops/${id}`, data);
  return res;
};
const editHideShop = async (data, id) => {
  const res = await http.post(`${API_VERSION_1_PREFIX}/shops/${id}/hide`, data);
  return res;
};

const getAds = async (params) => {
  const res = await http.get(`${API_VERSION_1_PREFIX}/promotions`, {
    params,
  });
  return res;
};
const getAdsDetail = async (id) => {
  const res = await http.get(`${API_VERSION_1_PREFIX}/promotions/${id}`);
  return res;
};
const createAds = async (data) => {
  const res = await http.post(`${API_VERSION_1_PREFIX}/promotions`, data);
  return res;
};
const editAds = async (data, id) => {
  const res = await http.post(`${API_VERSION_1_PREFIX}/promotions/${id}`, data);
  return res;
};
const deleteAds = async (id) => {
  const res = await http.delete(`${API_VERSION_1_PREFIX}/promotions/${id}`);
  return res;
};

const getCategory = async (params) => {
  const res = await http.get(`${API_VERSION_1_PREFIX}/categories`, {
    params,
  });
  return res;
};
const getCategoryDetail = async (id) => {
  const res = await http.get(`${API_VERSION_1_PREFIX}/categories/${id}`);
  return res;
};
const createCategory = async (data) => {
  const res = await http.post(`${API_VERSION_1_PREFIX}/categories`, data);
  return res;
};
const editCategory = async (data, id) => {
  const res = await http.post(`${API_VERSION_1_PREFIX}/categories/${id}`, data);
  return res;
};
const deleteCategory = async (id) => {
  const res = await http.delete(`${API_VERSION_1_PREFIX}/categories/${id}`);
  return res;
};

const getUsers = async (params) => {
  const res = await http.get(`${API_VERSION_1_PREFIX}/users`, {
    params,
  });
  return res;
};
const getProjects = async (params) => {
  const res = await http.get(`${API_VERSION_1_PREFIX}/projects`, {
    params,
  });
  return res;
};
const postFeaturedProjects = async (id, data) => {
  const res = await http.post(`${API_VERSION_1_PREFIX}/projects/${id}/featured`, data);
  return res;
};

export default {
  getShops,
  getShopDetail,
  createShop,
  editShop,
  editHideShop,
  getAds,
  getAdsDetail,
  createAds,
  editAds,
  deleteAds,
  getCategory,
  getCategoryDetail,
  createCategory,
  editCategory,
  deleteCategory,
  getUsers,
  getProjects,
  postFeaturedProjects,
};
