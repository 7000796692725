export const numberFormatter = (num, opts = { maximumFractionDigits: 12 }) => {
  const number = Number(num);

  if (isNaN(number)) return '-';
  return num !== undefined && num !== null ? number.toLocaleString('en-US', opts) : '-';
};

export const formatParamsApi = (params) => {
  let query_arr = [];
  let query = '';
  if (params && Object.keys(params).length) {
    Object.keys(params).forEach((i) => {
      if (params[i]) {
        query_arr.push(`${i}=${encodeURIComponent(params[i])}`);
      }
    });
  }
  if (query_arr.length) {
    query = '?' + query_arr.join('&');
  }
  return query;
};
