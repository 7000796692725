import { defineRule } from 'vee-validate';
import Web3 from 'web3';
import { compare } from '@/utils/bignumber.util';
import AllRules from '@vee-validate/rules';

export default function () {
  Object.keys(AllRules).forEach((rule) => {
    defineRule(rule, AllRules[rule]);
  });
  defineRule('checkTokenAddress', (value) => Web3.utils.isAddress(value));
  defineRule('lowerThan100', (value) => {
    return compare('lt')(value)(100);
  });
  defineRule('url', (value) => {
    try {
      if (!value) {
        return true;
      }
      var res = value.match(
        /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)/g
      );
      return res !== null;
    } catch (error) {
      return false;
    }
  });
  defineRule('checkTokenAddresses', (value) => {
    return (value?.split(/\n/) || []).every((item) => Web3.utils.isAddress(item));
  });
  defineRule('numbersStr', (value) => {
    const isValid = /^\d+(?:,\d+)*$/.test(value);
    if (!isValid) return false;
    let map = {};
    for (let val of value.split(',')) {
      if (map[Number(val)]) return false;
      map[Number(val)] = true;
    }
    return true;
  });
}
