import { APP_ENV, APP_NAME, APP_VERSION, FALLBACK_LOCALE, LOCALE } from '@/env';
import en from '@/lang/en.json';
import ja from '@/lang/ja.json';
import axios from 'axios';
import { createI18n } from 'vue-i18n';

const i18n = createI18n({
  locale:
    JSON.parse(window.localStorage.getItem(['vuex', APP_NAME, APP_VERSION, APP_ENV].join('-')))
      ?.setting?.locale || LOCALE,
  fallbackLocale: FALLBACK_LOCALE,
  silentTranslationWarn: true,
  messages: {
    en,
    ja,
  },
  legacy: false,
  fallbackWarn: false,
  silentFallbackWarn: false,
  missingWarn: false,
});

export const setI18nLanguage = (locale) => {
  i18n.global.locale.value = locale;
  axios.defaults.headers.common['Accept-Language'] = locale;
  // document.querySelector('html').setAttribute('lang', locale);
};

export default i18n;
