import BigNumber from 'bignumber.js';

export const APP_NAME = process.env.VUE_APP_NAME || 'matsuri';
export const APP_VERSION = process.env.VUE_APP_VERSION || '0.0.1';
export const APP_ENV = process.env.VUE_APP_ENV || 'local';
export const BASE_URL = process.env.VUE_APP_API_BASE_URL;
export const API_VERSION_1_PREFIX = '/api/v1';
export const CHAIN_ID = Number(process.env.VUE_APP_DEFAULT_CHAIN_ID) || 5;
export const SUPPORTED_CHAIN_IDS = process.env.VUE_APP_SUPPORTED_CHAIN_IDS.replace(' ', '')
  .split(',')
  .map((el) => Number(el));
//CONTRACTs

//OTHER
export const FASTEST_INTERVAL = process.env.VUE_APP_FAST_INTERVAL || 15000;
export const FAST_INTERVAL = process.env.VUE_APP_FAST_INTERVAL || 10000;
export const SLOW_INTERVAL = process.env.VUE_APP_SLOW_INTERVAL || 60000;
export const LOCALE = process.env.VUE_APP_I18N_LOCALE || 'ja';
export const FALLBACK_LOCALE = process.env.VUE_APP_I18N_FALLBACK_LOCALE || 'ja';
export const BLOCKS_PER_YEAR = new BigNumber(process.env.VUE_APP_BLOCKS_PER_YEAR || 6220800);
export const SYSTEM_DECIMALS = 6;

export const ZERO_ADDRESS = '0x0000000000000000000000000000000000000000';
export const CREATE_NFT_SITE = process.env.VUE_APP_CREATE_NFT_SITE;
export const SEND_NFT_SITE = process.env.VUE_APP_SEND_NFT_SITE;
export const JSON_SAMPLE_URL = process.env.VUE_APP_JSON_SAMPLE_URL;
export const WHITELIST_SAMPLE_URL = process.env.VUE_APP_WHITELIST_SAMPLE_URL;
export const SITE_URL = process.env.VUE_APP_SITE_URL;
export const WEB3AUTH_CLIENT_ID = process.env.VUE_APP_WEB3AUTH_CLIENT_KEY;
export const WEB3AUTH_SECRET_KEY = process.env.VUE_APP_WEB3AUTH_SECRET_KEY;
export const AFFILIATE_SAMPLE_URL = process.env.VUE_APP_AFFILIATE_SAMPLE_URL;
export const WC_CHAIN_IDS = (process.env.VUE_APP_WC_CHAIN_IDS || '1')
  .replace(' ', '')
  .split(',')
  .map((el) => Number(el));
export const WC_OPTIONAL_CHAIN_IDS = (
  process.env.VUE_APP_WC_OPTIONAL_CHAIN_IDS || '137, 11155111, 80001, 56, 592, 43114, 97, 81, 43113'
)
  .replace(' ', '')
  .split(',')
  .map((el) => Number(el));
