<template>
  <a-modal
    :visible="visible"
    @cancel="$emit('reject')"
    :maskStyle="{
      backgroundColor: 'rgba(255, 255, 255, 0.5)',
    }"
    :footer="null"
    :bodyStyle="{
      border: 'solid 2px #d72500',
    }"
    :closable="true"
  >
    <div class="flex flex-col mt-3 mb-3">
      <h3 class="text-center mb-0">{{ $t('TERMS_OF_SERVICES') }}</h3>
      <a-divider style="border-color: #d72500; border-width: 1px" />
      <div class="flex flex-col justify-center items-center">
        <span class="text-center"
          ><a
            href="/files/riyoukiyaku230703.pdf"
            target="_blank"
            class="underline"
            >{{ $t('TERMS_OF_SERVICES') }}</a
          >
          {{ $t('TERM_AND_CONDITION_MSG') }}
        </span>

        <div
          class="mt-3 flex flex-col"
          style="max-width: 300px; width: 100%"
        >
          <a-button
            size="large"
            type="primary"
            @click="$emit('accept')"
            >{{ $t('ACCEPT_CONNECT') }}</a-button
          >
          <a-button
            size="large"
            class="mt-2"
            @click="$emit('reject')"
            >{{ $t('REJECT_CONNECT') }}</a-button
          >
        </div>
      </div>
    </div>
  </a-modal>
</template>

<script>
import { ref } from 'vue';

export default {
  name: 'TermConditionModal',
  props: ['visible'],
  emits: ['update:visible', 'reject', 'accept'],
  setup(props) {
    return {};
  },
};
</script>
