import { getAddress } from '@ethersproject/address';
import moment from 'moment-timezone';
import { SYSTEM_DECIMALS } from '@/env';
import BigNumber from 'bignumber.js';

export function truncate(str, n, frontChars, backChars, separator) {
  /**
   * str: Input string
   * n: Number of character want to display
   * frontChars: Number of characters in front of separator
   * backChars: Number of characters in back of separator
   * seperator: Symbol want to display, default "..."
   */
  const sep = separator || '...';
  const sepLen = sep.length;
  if (str.length < n - sepLen) {
    return str;
  }
  return str.substr(0, frontChars) + sep + str.substr(str.length - backChars);
}

export function isAddress(value) {
  try {
    return getAddress(value);
  } catch {
    return false;
  }
}

export const getFileName = (url) => {
  if (!url) return url;
  if (url) {
    const splitted = url.split('/');
    return splitted[splitted.length - 1];
  }
  return '';
};

export const toJST = (value, format = 'MM-DD-YYYY HH:mm', isTBA = false) => {
  return isTBA ? 'TBA' : moment(value).tz('Asia/Tokyo').format(format);
};

export const toCurrency = (
  value,
  decimal = SYSTEM_DECIMALS,
  currency = null,
  rate = null,
  minimumDecimal = 0
) => {
  if (!value) {
    return 0;
  }
  value = BigNumber(value);
  if (currency && rate) {
    value = value.times(BigNumber(rate));
  }
  if (currency === 'JPY') {
    decimal = 1;
    if (value.toNumber() < 100) {
      decimal = 2;
    }
    if (value.toNumber() < 10) {
      decimal = 3;
    }
  }

  var formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    maximumFractionDigits: decimal,
    minimumFractionDigits: minimumDecimal,
  });
  return formatter.format(value.toFixed(decimal, 1)).replace('$', '');
};
