import { createApp } from 'vue';
import App from './app.vue';
import router from './router';
import store from './store';
import { VueQueryPlugin } from 'vue-query';
import { ConfigProvider } from 'ant-design-vue';

//styles
import './assets/styles/index.style.scss';
// plugins
import Notifications from '@kyvg/vue3-notification';

import AntDesignVue from 'ant-design-vue';
import 'ant-design-vue/dist/antd.variable.min.css';

import VueCountdown from '@chenfengyuan/vue-countdown';

import Vue3Sanitize from 'vue-3-sanitize';
import { SANITIZE_CONFIG } from './constants/types.constant';

import configVeeValidate from '@/plugins/vee-validate';

import configCleave from '@/plugins/cleave';

import i18n from './plugins/i18n';

import connectorManager from '@/plugins/connector-manager';
import upbondConnector from '@/plugins/upbond-connector';
import metamaskConnector from '@/plugins/metamask-connector';
import wcConnector from '@/plugins/wc-connector';
import metamaskMobileConnector from '@/plugins/metamask-mobile-connector';
try {
  await wcConnector.setup();
  await metamaskMobileConnector.setup();
  const app = createApp(App);

  app.use(Notifications);

  app.use(AntDesignVue);

  app.component(VueCountdown.name, VueCountdown);

  app.use(Vue3Sanitize, SANITIZE_CONFIG);

  configVeeValidate();

  configCleave(app);

  app.use(i18n);

  app.use(router);
  app.use(store);

  app.use(VueQueryPlugin, {
    queryClientConfig: {
      defaultOptions: {
        queries: {
          refetchOnWindowFocus: false,
          retry: false,
        },
      },
    },
  });
  app.provide('upbondConnector', upbondConnector);
  app.provide('wcConnector', wcConnector);

  app.provide('metamaskMobileConnector', metamaskMobileConnector);

  app.provide('metamaskConnector', metamaskConnector);
  app.provide('authConnector', connectorManager);

  app.mount('#app');
  ConfigProvider.config({
    theme: {
      primaryColor: '#d72500',
    },
  });
} catch (err) {
  console.error(err);
}
