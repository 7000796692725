import http from './axios';
import { API_VERSION_1_PREFIX } from '@/env';

export const getProjectsList = async ({
  limit = 10,
  offset = 0,
  name = '',
  type = undefined,
  orderBy = 'created_at',
}) => {
  try {
    const response = await http.get(`${API_VERSION_1_PREFIX}/projects`, {
      params: {
        limit,
        offset,
        name,
        type,
        order_by: orderBy,
        sort_order: 'desc',
      },
    });

    return response;
  } catch (error) {
    console.error(error);
    return null;
  }
};

export const getTransactionHistory = async ({ limit = 10, offset = 0, projectId }) => {
  try {
    const response = await http.get(
      `${API_VERSION_1_PREFIX}/marketplace/projects/${projectId}/tx`,
      {
        params: {
          limit,
          offset,
        },
      }
    );

    return response;
  } catch (error) {
    console.error(error);
    return null;
  }
};

export const createProject = async (formData) => {
  const data = await http.post(`${API_VERSION_1_PREFIX}/projects`, formData);
  return data;
};

export const startProject = async (projectId) => {
  const data = await http.post(`${API_VERSION_1_PREFIX}/projects/${projectId}/start`);
  return data;
};

export const submitProject = async (projectId) => {
  const data = await http.post(`${API_VERSION_1_PREFIX}/projects/${projectId}/submit`);
  return data;
};

export const approveProject = async (projectId) => {
  const data = await http.post(`${API_VERSION_1_PREFIX}/projects/${projectId}/approve`);
  return data;
};

export const rejectProject = async (projectId) => {
  const data = await http.post(`${API_VERSION_1_PREFIX}/projects/${projectId}/reject`);
  return data;
};

export const deleteProject = async (projectId) => {
  const data = await http.delete(`${API_VERSION_1_PREFIX}/projects/${projectId}`);
  return data;
};

export const getProjectDetail = async (id) => {
  const response = await http.get(`${API_VERSION_1_PREFIX}/projects/${id}`);

  return response;
};

export const getNftDetail = async (id) => {
  const response = await http.get(`${API_VERSION_1_PREFIX}/marketplace/nfts/${id}`);

  return response;
};

export const getSignature = async ({ projectId, amount, saleType }) => {
  const res = await http.get(`${API_VERSION_1_PREFIX}/projects/${projectId}/signature`, {
    params: {
      action: 'buy',
      amount: amount,
      sale_type: saleType,
    },
  });
  if (!res || !res.signature) {
    throw new Error('Signature not found');
  }
  return res.signature;
};

export const getSignatureForCreateContractProject = async (projectId) => {
  const res = await http.get(
    `${API_VERSION_1_PREFIX}/projects/${projectId}/signature?action=create`
  );
  return res;
};

export const getSignatureClaimFee = async (projectId) => {
  const res = await http.get(
    `${API_VERSION_1_PREFIX}/projects/${projectId}/signature?action=claimFee`
  );
  if (!res || !res.signature) {
    throw new Error('Signature not found');
  }
  return res.signature;
};

export const toggleShowHide = async (projectId, isHidden) => {
  const res = await http.post(`${API_VERSION_1_PREFIX}/projects/${projectId}/hide`, {
    is_hidden: isHidden,
  });
  return res;
};

export const editProject = async (projectId, formData) => {
  const res = await http.post(`${API_VERSION_1_PREFIX}/projects/${projectId}`, formData);
  return res;
};

export const uploadImg = async (formData) => {
  const res = await http.post(`${API_VERSION_1_PREFIX}/image/upload`, formData);
  return res;
};

export const uploadWhitelist = async (projectId, formData) => {
  const res = await http.post(`${API_VERSION_1_PREFIX}/projects/${projectId}/whitelist`, formData);
  return res;
};

export const downloadCSV = async (startTime, endTime) => {
  const res = await http.get(`${API_VERSION_1_PREFIX}/export/sales`, {
    params: {
      from_date: startTime,
      to_date: endTime,
    },
    responseType: 'blob',
  });
  return res;
};

export const getSignatureImportTokens = async ({ projectId }) => {
  try {
    const res = await http.get(`${API_VERSION_1_PREFIX}/projects/${projectId}/signature`, {
      params: {
        action: 'importNFT',
      },
    });
    if (!res || !res.signature) {
      throw new Error('Signature not found');
    }
    return res.signature;
  } catch (error) {
    console.log(error);
    return null;
  }
};
