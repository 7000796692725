<template>
  <a-config-provider :locale="locale === 'ja' ? jaJP : enUS">
    <slot></slot>
  </a-config-provider>
</template>

<script>
import { mapState, useStore } from 'vuex';
import { computed, watch } from 'vue';

import enUS from 'ant-design-vue/es/locale/en_US';
import jaJP from 'ant-design-vue/es/locale/ja_JP';
import dayjs from 'dayjs';
import 'dayjs/locale/ja';

dayjs.locale('ja');

export default {
  setup: () => {
    const store = useStore();

    const locale = computed(() => store.state?.setting.locale || 'ja');

    watch(locale, (val) => {
      dayjs.locale(val);
    });

    return {
      locale,
      enUS,
      jaJP,
    };
  },
};
</script>

<style lang="scss"></style>
